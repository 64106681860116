import { formatToDollar } from "@inrev/common";
import { ColumnDef } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { getStatusTagByStatus } from "../../../components/ui/StatusTag";
import { bondRequestTypeLabelMap } from "../../../constants";
import { BondRequestPreview } from "../../../domain/agent/request/types";
import { formatEmpty } from "../../../utils";

export const requestsTableColumns: ColumnDef<BondRequestPreview>[] = [
	{
		id: "status",
		accessorKey: "status",
		header: "Status",
		filterFn: "equalsString",
		cell: (cell) => getStatusTagByStatus(cell.row.original.status),
		meta: {
			noTruncate: true,
		},
		size: 175,
		minSize: 175,
	},
	{
		id: "principal_name",
		accessorKey: "principalName",
		accessorFn: ({ principalName }) => formatEmpty(principalName),
		header: "Principal",
	},
	{
		id: "amount",
		accessorKey: "amount",
		header: "Amount",
		accessorFn: ({ amount }) => formatEmpty(formatToDollar(amount)),
	},
	{
		id: "description",
		accessorKey: "description",
		accessorFn: ({ description }) => formatEmpty(description),
		header: "Description",
	},
	{
		id: "type",
		accessorKey: "suretySubType",
		accessorFn: ({ suretySubType }) =>
			formatEmpty(!!suretySubType ? bondRequestTypeLabelMap[suretySubType] : undefined),
		header: "Type",
	},
	{
		id: "account_name",
		accessorKey: "accountName",
		accessorFn: ({ accountName }) => formatEmpty(accountName),
		header: "Account",
	},
	{
		id: "submittedAt",
		accessorKey: "submittedAt",
		accessorFn: ({ submittedAt }) =>
			submittedAt !== undefined ? DateTime.fromISO(submittedAt).toJSDate() : undefined,
		cell: (props) =>
			props.row.original.submittedAt !== undefined
				? DateTime.fromISO(props.row.original.submittedAt).toLocaleString(DateTime.DATE_MED)
				: "--",
		header: "Submitted",
		size: 150,
		enableGlobalFilter: false,
	},
];

export const requestsTableColumnsDefaultSizing = {
	principal: 180,
	account: 180,
	submitted: 180,
	amount: 180,
	description: 180,
	type: 180,
	status: 180,
};
