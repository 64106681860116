import { Dtos } from "@inrev/common";
import {
	Document,
	PDFDownloadLink,
	Image as PDFImage,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";
import { DateTime } from "luxon";
import { HiOutlineArrowTopRightOnSquare } from "react-icons/hi2";
import { Spinner } from "./Spinner";

type StyledText = {
	text: string;
	styling: string[];
};

const styles = StyleSheet.create({
	page: {
		flexDirection: "row",
		backgroundColor: "white",
		position: "relative",
		marginVertical: 20,
		paddingBottom: 40,
	},
	commentContainer: {
		margin: 20,
		paddingHorizontal: 10,
		rowGap: 12,
		flexShrink: 1,
	},

	message: {
		fontFamily: "Helvetica",
		fontSize: 12,
		fontWeight: "normal",
	},
	title: {
		fontFamily: "Helvetica",
		fontSize: 18,
	},
	portalLink: {
		fontFamily: "Helvetica",
		fontSize: 10,
	},
	Subtitle: {
		fontFamily: "Helvetica",
		fontSize: 14,
	},
});

function parseCommentMessageForPDF(message: string): StyledText[] {
	if (message.slice(0, 6) === "giphy:") {
		return [
			{
				text: `https://i.giphy.com/${filterXSS(message.split(":")[1], { whiteList: {}, stripIgnoreTag: true })}.webp`,
				styling: ["image"],
			},
		];
	}
	return parseStyledText(filterXSS(message));
}

function getPngFromGiphyUrl(url: string): Promise<string> {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.crossOrigin = "anonymous";
		img.onload = () => {
			const height = 200;
			const aspectRatio = img.width / img.height;
			const scaledWidth = height * aspectRatio;
			const canvas = document.createElement("canvas");
			canvas.width = scaledWidth;
			canvas.height = height;

			const ctx = canvas.getContext("2d");
			if (!ctx) {
				reject("Failed to get canvas context");
				return;
			}
			ctx.drawImage(img, 0, 0, scaledWidth, height);

			const dataURL = canvas.toDataURL(`image/png`);
			resolve(dataURL);
		};
		img.onerror = () => {
			reject("Failed to load the image");
		};
		img.src = url;
	});
}

function parseStyledText(input: string): StyledText[] {
	const result: StyledText[] = [];
	const tagStyles: { [key: string]: string } = {
		u: "underline",
		// Add more tags if needed (font styles get a bit weird in PDFs)
	};

	// Strip out any html tags and split the text into an array of text and tags
	const regex = /<\/?(\w+)(?:\s[^>]*)?>|([^<]+)/g;
	let match: RegExpExecArray | null;

	const styleStack: string[] = [];

	while ((match = regex.exec(input)) !== null) {
		if (match[2]) {
			const text = match[2].trim();
			if (text) {
				result.push({ text: text, styling: [...styleStack] });
			}
		} else if (match[1]) {
			const tag = match[1].toLowerCase();

			if (tagStyles[tag]) {
				styleStack.push(tagStyles[tag]);
			} else if (tag.startsWith("/")) {
				const closingTag = tag.slice(1);
				const styleToRemove = tagStyles[closingTag];

				const index = styleStack.lastIndexOf(styleToRemove);
				if (index !== -1) {
					styleStack.splice(index, 1);
				}
			}
		}
	}

	return result;
}

const dealWithExtraLongText = (text: string) => {
	const splitText = text.split(" ");
	return splitText.reduce(
		(acc, curr) => {
			if (acc[acc.length - 1].length + curr.length > 500) {
				acc.push(curr);
			} else {
				acc[acc.length - 1] += ` ${curr}`;
			}
			return acc;
		},
		[""],
	);
};

const textStyle = StyleSheet.create({
	underline: {
		textDecoration: "underline",
	},
	link: {
		textDecoration: "underline",
		color: "blue",
	},
});

const PDFComments = ({
	comments,
	commentDownloadHeader,
}: {
	comments: Dtos.Comment.Get.Response[];
	commentDownloadHeader?: string;
}) => (
	<Document>
		<Page size="A4" style={styles.page} wrap>
			<View style={styles.commentContainer}>
				{commentDownloadHeader && (
					<View style={{ paddingBottom: 10 }}>
						<Text style={styles.title}>inRev Comments on {commentDownloadHeader}</Text>
					</View>
				)}
				{comments.map((comment) => (
					<PDFComment key={comment.id} comment={comment} />
				))}
			</View>
		</Page>
	</Document>
);

const PDFTextStyling = ({ text, styling }: StyledText) => {
	const styled: any = [];
	styling.forEach((s) => {
		if (textStyle[s as keyof typeof textStyle]) {
			styled.push(textStyle[s as keyof typeof textStyle]);
		}
	});
	if (styling.includes("image")) {
		return (
			<PDFImage
				style={{ height: 150, objectFit: "scale-down", objectPosition: "left" }}
				cache={false}
				src={getPngFromGiphyUrl(text)}
			/>
		);
	}

	if (text.length > 500) {
		return dealWithExtraLongText(text).map((t) => (
			<Text style={[styles.message, ...styled]} wrap>
				{t}
			</Text>
		));
	}

	return (
		<Text style={[styles.message, ...styled]} wrap={false}>
			{text}
		</Text>
	);
};

const PDFComment = ({ comment }: { comment: Dtos.Comment.Get.Response }) => (
	<View
		style={{ width: "90vw", paddingBottom: 15, borderBottom: 1, borderColor: "gray" }}
		wrap={comment.message.length > 500 ? true : false}
	>
		<View style={{ paddingBottom: 5 }}>
			<Text style={styles.Subtitle}>
				{comment.fromUserFirstName} {comment.fromUserLastName}{" "}
				<Text style={styles.message}>{comment.fromUserType === "admin" ? "(inRev) " : ""}</Text>
				<Text style={styles.message}>
					- {DateTime.fromISO(comment.createdAt).toLocaleString(DateTime.DATETIME_SHORT)}
				</Text>
			</Text>
		</View>
		<View style={{ paddingLeft: 15 }}>
			{parseCommentMessageForPDF(comment.message).map((m) => (
				<PDFTextStyling text={m.text} styling={m.styling} />
			))}
		</View>
	</View>
);

export const DownloadLink = ({
	fileName = `comments-${new Date().toISOString()}`,
	commentDownloadHeader,
	comments,
}: {
	fileName: string;
	commentDownloadHeader?: string;
	comments: Dtos.Comment.Get.Response[];
}) => (
	<PDFDownloadLink
		document={<PDFComments comments={comments} commentDownloadHeader={commentDownloadHeader} />}
		fileName={`${fileName}.pdf`}
	>
		{({ loading }) =>
			loading ? (
				<span className="">
					<Spinner />
				</span>
			) : (
				<span className="text-gray-600 hover:text-gray-800">
					<HiOutlineArrowTopRightOnSquare className="text-[19px]" />
				</span>
			)
		}
	</PDFDownloadLink>
);
