import { RadioGroup } from "@headlessui/react";
import { FileExtension, FileId, FileType, SuretyBondFormType, UploadedFile } from "@inrev/common";
import { useEffect, useMemo, useState } from "react";
import { FieldError } from "react-hook-form";
import { HiOutlineBookOpen, HiXMark } from "react-icons/hi2";
import { v4 } from "uuid";
import { bondFormTypeLabelMap } from "../../constants";
import { useFileUpload } from "../../domain/agent/request/api";
import { BondFormTemplate, BondFormTemplatePreview } from "../../domain/agent/request/types";
import { BondFormData } from "../../types";
import { getNameAndExtensionFromFileName } from "../../utils";
import { useRequest } from "../../utils/request";
import { ConfirmationModalClickWrapper } from "../layout/ConfirmationModalClickWrapper";
import { Modal } from "../layout/Modal";
import { ModalItemWithHeader } from "../layout/ModalItemWithHeader";
import { FormError } from "../layout/form/FormError";
import { FormSection } from "../layout/form/FormSection";
import { cn } from "../lib/utils";
import { Button } from "./Button";
import { Dropzone } from "./Dropzone";
import { FileUploadItem, PendingFileUploadItem } from "./FileUploadItem";
import { RadioOption } from "./RadioOption";

export type BondFormSelectProps = {
	value: BondFormData;
	onChange: (value: BondFormData) => void;
	onBlur: () => void;
	onDownload: (id: string) => void;
	bondFormTemplates: BondFormTemplate[];
	allowedBondFormUploadTypesAndLabels: Partial<Record<SuretyBondFormType, string>>;
	header?: string;
	marker?: boolean;
	error?: FieldError;
	id?: string;
};

export const BondFormTypeSelectModal = ({
	allowedBondFormUploadTypesAndLabels,
	handleUploadTypeSelection,
}: {
	allowedBondFormUploadTypesAndLabels: Partial<Record<SuretyBondFormType, string>>;
	handleUploadTypeSelection: (type: SuretyBondFormType) => void;
}) => {
	const [selectedFormType, setSelectedFormType] = useState<SuretyBondFormType | "">("");

	return (
		<div
			className={
				"py-[35px] px-[35px] bg-white flex flex-col justify-center rounded-md shadow-lg space-y-[35px]"
			}
		>
			<div className="text-[16px] text-gray-600 font-thin">{"What type of bond form is this?"}</div>
			<RadioGroup
				value={selectedFormType}
				onChange={(value) => setSelectedFormType(value)}
				className="flex flex-col space-y-[35px]"
			>
				{(
					Object.entries(allowedBondFormUploadTypesAndLabels) as [SuretyBondFormType, string][]
				).map(([type, label]) => (
					<RadioOption
						value={type}
						onSelect={() => setSelectedFormType(type)}
						label={label}
						key={type}
					/>
				))}
			</RadioGroup>
			<Button
				color="light-blue"
				filled
				disabled={selectedFormType === ""}
				onClick={() => selectedFormType !== "" && handleUploadTypeSelection(selectedFormType)}
			>
				Upload
			</Button>
		</div>
	);
};

export const BondFormSelect = ({
	value: bondFormData,
	onChange,
	onBlur,
	onDownload,
	bondFormTemplates,
	allowedBondFormUploadTypesAndLabels,
	header,
	marker,
	error,
	id,
}: BondFormSelectProps) => {
	const [[showLibrary, showUploadTypeSelect], setShow] = useState<[boolean, boolean]>([
		false,
		false,
	]);
	const selectedTemplate = useMemo(
		() => bondFormTemplates.find((template) => template.id === bondFormData.templateId),
		[bondFormData],
	);
	const [filesPendingUpload, setFilesPendingUpload] = useState<
		UploadedFile<typeof FileType.blank_bond_form>[]
	>([]);
	const [fileIdsPendingDelete, setFileIdsPendingDelete] = useState<string[]>([]);
	const filesUploaded = bondFormData.upload.files.length > 0 || filesPendingUpload.length > 0;
	const [fileForTypeSelection, setFileForTypeSelection] = useState<File | undefined>();
	const bondFormUploadType = useMemo(() => bondFormData.upload.type, [bondFormData.upload.type]); // Used to break closure in useFileUpload onSuccess cb
	const allowedTypes = useMemo(
		() => Object.keys(allowedBondFormUploadTypesAndLabels) as unknown as SuretyBondFormType[],
		[allowedBondFormUploadTypesAndLabels],
	);
	const { get } = useRequest();

	const { uploadFn: uploadFile, deleteFn: deleteFile } = useFileUpload<
		typeof FileType.blank_bond_form
	>(
		(data, args) =>
			((
				data: UploadedFile<typeof FileType.blank_bond_form>,
				args: { pendingFile: UploadedFile<typeof FileType.blank_bond_form> },
			) => {
				setFilesPendingUpload(filesPendingUpload.filter((file) => file.id !== args.pendingFile.id));
				onChange({
					templateId: "",
					upload: { type: bondFormUploadType, files: [...bondFormData.upload.files, data] },
				});
			})(data, args),
		(error, args) => {
			console.error(error);
			if (bondFormData.upload.files.length + filesPendingUpload.length === 1) {
				setFilesPendingUpload([]);
				onChange({ templateId: "", upload: { type: "", files: [] } });
			} else {
				setFilesPendingUpload(filesPendingUpload.filter((file) => file.id !== args.pendingFile.id));
			}
		},
		(args) => {
			if (bondFormData.upload.files.length + filesPendingUpload.length === 1) {
				onChange({ templateId: "", upload: { type: "", files: [] } });
			} else {
				onChange({
					templateId: "",
					upload: {
						...bondFormData.upload,
						files: bondFormData.upload.files.filter((file) => file.id !== args.id),
					},
				});
			}
			setFileIdsPendingDelete(fileIdsPendingDelete.filter((id) => id !== args.id));
		},
		(error, args) => {
			console.error(error);
			setFileIdsPendingDelete(fileIdsPendingDelete.filter((id) => id !== args.id));
		},
	);

	const handleUpload = (file: File) => {
		const { fileName, extension } = getNameAndExtensionFromFileName(file.name);
		if (bondFormData.upload.type === "") {
			if (allowedTypes.length > 1) {
				setFileForTypeSelection(file);
			} else {
				const uploadArgs: { rawFile: File; pendingFile: BondFormData["upload"]["files"][number] } =
					{
						rawFile: file,
						pendingFile: {
							id: v4() as FileId,
							name: fileName,
							extension,
							types: [FileType.blank_bond_form],
						},
					};
				onChange({ templateId: "", upload: { ...bondFormData.upload, type: allowedTypes[0] } });
				setFilesPendingUpload([...filesPendingUpload, uploadArgs.pendingFile]);
				uploadFile(uploadArgs);
			}
		} else {
			const uploadArgs: { rawFile: File; pendingFile: BondFormData["upload"]["files"][number] } = {
				rawFile: file,
				pendingFile: {
					id: v4() as FileId,
					name: fileName,
					extension,
					types: [FileType.blank_bond_form],
				},
			};
			setFilesPendingUpload([...filesPendingUpload, uploadArgs.pendingFile]);
			uploadFile(uploadArgs);
		}
	};

	const handleDelete = (id: string) => {
		setFileIdsPendingDelete([...fileIdsPendingDelete, id]);
		deleteFile({ id });
	};

	const handleUploadTypeSelection = (formType: SuretyBondFormType) => {
		if (!fileForTypeSelection) throw new Error();
		const { fileName, extension } = getNameAndExtensionFromFileName(fileForTypeSelection.name);
		const uploadArgs: { rawFile: File; pendingFile: BondFormData["upload"]["files"][number] } = {
			rawFile: fileForTypeSelection,
			pendingFile: {
				id: v4() as FileId,
				name: fileName,
				extension,
				types: [FileType.blank_bond_form],
			},
		};
		onChange({ templateId: "", upload: { ...bondFormData.upload, type: formType } });
		setFilesPendingUpload([...filesPendingUpload, uploadArgs.pendingFile]);
		uploadFile(uploadArgs);
		onClose();
	};

	const handleTemplateSelect = (id: string) => {
		onChange({ templateId: id, upload: { type: "", files: [] } });
	};

	const handleTemplateDeselect = () => {
		onChange({ templateId: "", upload: { type: "", files: [] } });
	};

	const onClose = () => {
		setFileForTypeSelection(undefined);
		setShow([false, false]);
	};

	useEffect(() => {
		onBlur();
	}, [bondFormData]);

	useEffect(() => {
		if (fileForTypeSelection) {
			setShow([false, true]);
		}
	}, [fileForTypeSelection]);

	return (
		<FormSection
			header={header ?? "Bond Form"}
			marker={marker}
			subHeader={
				!selectedTemplate && !filesUploaded
					? "Please select a bond form from the library, or upload your own"
					: undefined
			}
			topPadding
		>
			<div
				className={cn(
					"flex flex-col space-y-[30px] max-w-[500px]",
					error ? "relative rounded-md outline outline-offset-[10px] outline-red-500" : undefined,
				)}
				id={id}
			>
				{!selectedTemplate && !filesUploaded && (
					<div className="flex items-center space-x-[15px] text-[12px] text-gray-600 font-medium">
						<Button
							className="ml-[-5px] w-fit"
							color="gray"
							rounded
							filled
							thinFont
							onClick={() => setShow([true, false])}
						>
							<div className="flex min-w-fit items-center space-x-[8px]">
								<HiOutlineBookOpen className="text-[17px] text-gray-800 stroke-[2px]" />
								<div className="min-w-fit">Select a Bond Form</div>
							</div>
						</Button>
						<span>- OR -</span>
					</div>
				)}
				{selectedTemplate && (
					<div className="min-h-[50px] w-[450px] break-words flex items-center space-x-[25px] p-[15px] bg-white border border-gray-200 rounded-[5px] shadow-sm text-[15px] text-gray-900">
						<HiOutlineBookOpen className="text-[25px] text-gray-700" />
						<div className="flex-1 flex flex-col space-y-[2px]">
							<span className="font-medium">{selectedTemplate.name}</span>
							<span className="text-[13px] text-gray-500">
								{allowedBondFormUploadTypesAndLabels[selectedTemplate.type]} Bond
							</span>
						</div>
						<ConfirmationModalClickWrapper
							message={
								<div className="w-[375px] text-center break-words">
									Are you sure you want to remove
									<br />
									{selectedTemplate.name}?
								</div>
							}
							className="items-center"
							confirmButtonText="Remove"
							confirmationButtonClassName="border-[1px] border-gray-700 bg-white text-red-500"
							onConfirm={handleTemplateDeselect}
						>
							<HiXMark className="text-[19px] stroke-[2px] text-gray-400 hover:text-gray-800 cursor-pointer" />
						</ConfirmationModalClickWrapper>
					</div>
				)}
				{!selectedTemplate && (
					<div className="w-full flex flex-col space-y-[15px]">
						<Dropzone
							allowedExtensions={[
								FileExtension.pdf,
								FileExtension.png,
								FileExtension.jpg,
								FileExtension.jpeg,
								FileExtension.gif,
								FileExtension.docx,
							]}
							onSuccess={handleUpload}
						/>
						{bondFormData.upload.type !== "" && (
							<div className="w-full max-w-full flex flex-col space-y-[10px] cursor-default">
								{bondFormData.upload.files.map((file) => (
									<FileUploadItem
										key={`uploaded:${file.id}`}
										{...file}
										typeLabelMap={allowedBondFormUploadTypesAndLabels}
										onDelete={handleDelete}
										onDownload={() => onDownload(file.id)}
										deleting={fileIdsPendingDelete.includes(file.id)}
									/>
								))}
								{filesPendingUpload.map((file) => (
									<PendingFileUploadItem
										key={`pending:${file.id}`}
										{...file}
										typeLabelMap={allowedBondFormUploadTypesAndLabels}
									/>
								))}
							</div>
						)}
					</div>
				)}
				{error && <FormError error={error} className="!m-0 top-[-19px]" />}
			</div>
			{showLibrary && (
				<Modal onClickOutside={onClose} itemClassName="mb-[5%]">
					<ModalItemWithHeader
						header={
							<div className="flex items-center space-x-[8px]">
								<HiOutlineBookOpen className="text-[17px] stroke-[2px] text-gray-600" />
								<div>Bond Form Library</div>
							</div>
						}
						className="w-[550px] top-1/4 bottom-1/4"
						onClose={onClose}
					>
						<div className="w-full h-full flex flex-col space-y-[15px] px-[35px] pt-[20px] pb-[35px]">
							<div className="w-full text-[14px] text-gray-600">
								Select from {bondFormTemplates.length} bond form template
								{bondFormTemplates.length === 1 ? "" : "s"}
							</div>
							<div className="w-full flex-1 flex flex-col space-y-[10px]">
								{bondFormTemplates.map((template) => (
									<div
										key={template.id}
										className="w-full flex items-center rounded-sm bg-gray-50 border border-gray-200 px-[20px] py-[15px]"
									>
										<div className="flex-1 flex flex-col space-y-[10px]">
											<div className="flex flex-col space-y-[3px]">
												<div className="text-[15px] text-gray-800 font-semibold">
													{template.name}
												</div>
												<div className="text-[14px] text-gray-500">
													{bondFormTypeLabelMap[template.type]}
												</div>
											</div>
											<div className="flex spaxe-x-[5px]">
												{template.tags.map((tag, index) => (
													<div
														key={index}
														className="px-[8px] py-[3px] bg-white rounded-sm border border-gray-300 text-[13px] text-gray-700 font-medium"
													>
														{tag}
													</div>
												))}
											</div>
										</div>
										<div className="flex flex-col space-y-[5px]">
											{/* <a href={template.url} target="_blank"> */}
											<Button
												color="gray"
												onClick={async () => {
													try {
														const data = await get<BondFormTemplatePreview>(
															`/v2/surety/bond-forms/templates/${template.id}/preview`,
														);
														window.open(data.url, "_blank");
													} catch (error) {
														console.error(`Failed to fetch url ${error}`);
													}
												}}
												thinFont
											>
												Preview
											</Button>
											{/*</a>*/}
											<Button
												color="light-blue"
												filled
												thinFont
												onClick={() => {
													handleTemplateSelect(template.id);
													onClose();
												}}
											>
												Select
											</Button>
										</div>
									</div>
								))}
							</div>
						</div>
					</ModalItemWithHeader>
				</Modal>
			)}
			{showUploadTypeSelect && (
				<Modal onClickOutside={onClose}>
					<BondFormTypeSelectModal
						allowedBondFormUploadTypesAndLabels={allowedBondFormUploadTypesAndLabels}
						handleUploadTypeSelection={handleUploadTypeSelection}
					/>
				</Modal>
			)}
		</FormSection>
	);
};
