import { phone } from 'phone';
import { z } from 'zod';
import { commonLibraryContext } from '../context.mjs';
import { usStateOrTerritorySchema } from '../enums.mjs';

const encrypted = (schema) => schema.brand();
const decrypted = (schema) => schema.brand();
const hashed = (schema) => schema.brand();
const entityId = z.string().uuid().brand();
const agencyId = entityId.brand();
const agentId = entityId.brand();
const suretyBondFormTemplateId = entityId.brand();
const suretyBondFormFileUploadId = entityId.brand();
const suretyQuoteDraftBondFormFileUploadId = entityId.brand();
const carrierAgencyRelationshipId = entityId.brand();
const suretyCarrierAgencyRelationshipId = entityId.brand();
const contractSuretyCarrierAgencyRelationshipId = entityId.brand();
const carrierSuretyDetailsId = entityId.brand();
const carrierContractSuretyDetailsId = entityId.brand();
const suretyBondNumberCounterId = entityId.brand();
const suretyBondNumberCacheId = entityId.brand();
const suretyCarrierPoaId = entityId.brand();
const suretyIndemnityAgreementId = entityId.brand();
const additionalIndemnitorRiderId = entityId.brand();
const suretyBondFormId = entityId.brand();
const bidSuretyBondFormId = entityId.brand();
const contractSuretyBondFormId = entityId.brand();
const indemnitorReleaseId = entityId.brand();
const eSignatureId = entityId.brand();
const eSignatureVendorId = entityId.brand();
const attachmentId = entityId.brand();
const fileUploadId = entityId.brand();
const fileId = entityId.brand();
const filePreviewId = entityId.brand();
const individualContactId = entityId.brand();
const companyContactId = entityId.brand();
const financialStatementId = entityId.brand();
const workInProgressStatementId = entityId.brand();
const individualCreditReportId = entityId.brand();
const individualOFACCheckId = entityId.brand();
const companyCreditReportId = entityId.brand();
const indemnificationId = entityId.brand();
const indemnityWaiverId = entityId.brand();
const suretyAccountUnderwritingId = entityId.brand();
const suretyAccountCarrierBondingLineId = entityId.brand();
const suretyCompanyBondingLineId = entityId.brand();
const paymentId = entityId.brand();
const suretyAccountId = entityId.brand();
const suretyQuoteId = entityId.brand();
const suretyBondIssuanceId = entityId.brand();
const bidSuretyBondIssuanceId = entityId.brand();
const contractSuretyBondIssuanceId = entityId.brand();
const suretyBondId = entityId.brand();
const bidSuretyBondId = entityId.brand();
const contractSuretyBondId = entityId.brand();
const suretyBondPeriodId = entityId.brand();
const suretyBondRenewalPeriodTemplateId = entityId.brand();
const eventId = entityId.brand();
const suretyAccountReviewReasonId = entityId.brand();
const suretyQuoteReviewReasonId = entityId.brand();
const commentId = entityId.brand();
const followerId = entityId.brand();
const entityName = z.string().brand();
const carrierName = entityName.brand();
const paymentVendorName = entityName.brand();
const authzId = z.string().brand();
const email = z.string().toLowerCase().email("Invalid Email").brand();
const firstName = z.string().brand();
const lastName = z.string().brand();
const npn = z.string().brand();
const phoneNumber = z
    .preprocess((val) => {
    const result = phone(val);
    if (result.isValid)
        return result.phoneNumber;
}, z.custom((val) => {
    return typeof val === "string" && val.length > 0;
}, "Invalid Phone Number"))
    .brand();
const ssn = z.string().brand();
const decryptedSSN = decrypted(z
    .string()
    .regex(/[0-9]*\d{9}$/, "Invalid SSN")
    .brand());
const fein = z
    .string()
    .trim()
    .regex(/^[0-9]*$/, "Must be a number")
    .brand();
const streetAddress = z.string().brand();
const city = z.string().brand();
const zipCode = z.string().brand();
const addressSchema = z.object({
    street: streetAddress,
    city: city,
    state: usStateOrTerritorySchema,
    zip: zipCode,
});
const partialAddressSchema = addressSchema.partial();
var GoogleMaps;
(function (GoogleMaps) {
    (function (AddressComponentsEntityConfirmationLevel) {
        AddressComponentsEntityConfirmationLevel["CONFIRMED"] = "CONFIRMED";
        AddressComponentsEntityConfirmationLevel["UNCONFIRMED_BUT_PLAUSIBLE"] = "UNCONFIRMED_BUT_PLAUSIBLE";
        AddressComponentsEntityConfirmationLevel["UNCONFIRMED_AND_SUSPICIOUS"] = "UNCONFIRMED_AND_SUSPICIOUS";
    })(GoogleMaps.AddressComponentsEntityConfirmationLevel || (GoogleMaps.AddressComponentsEntityConfirmationLevel = {}));
})(GoogleMaps || (GoogleMaps = {}));
const getValidatedAddress = async (address) => {
    if (address.length === 0)
        return;
    const cachedAddress = await (async () => {
        if (commonLibraryContext.checkValidAddressCacheFn)
            return await commonLibraryContext.checkValidAddressCacheFn(address);
        return undefined;
    })();
    if (cachedAddress !== undefined)
        return cachedAddress;
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const raw = JSON.stringify({
        address: {
            regionCode: "US",
            addressLines: [address],
        },
    });
    const requestOptions = {
        method: "POST",
        headers: headers,
        body: raw,
        redirect: "follow",
    };
    console.log("fetching address validation: ", raw);
    const response = await fetch("https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyDo-u74TizCs5NYSicK9vlTpIvaQRBmGdU", requestOptions);
    const parsedResponse = (await response.json());
    console.log("address validation response: ", parsedResponse);
    const postBox = parsedResponse.result.address.addressComponents?.find((component) => component.componentType === "post_box");
    const streetNumber = parsedResponse.result.address.addressComponents?.find((component) => component.componentType === "street_number");
    const route = parsedResponse.result.address.addressComponents?.find((component) => component.componentType === "route");
    const subpremise = parsedResponse.result.address.addressComponents?.find((component) => component.componentType === "subpremise");
    const neighborhood = parsedResponse.result.address.addressComponents?.find((component) => component.componentType === "neighborhood");
    const locality = parsedResponse.result.address.addressComponents?.find((component) => component.componentType === "locality");
    const subLocality = parsedResponse.result.address.addressComponents?.find((component) => component.componentType === "sublocality_level_1");
    const administrativeAreaLevel1 = parsedResponse.result.address.addressComponents?.find((component) => component.componentType === "administrative_area_level_1");
    const postalCode = parsedResponse.result.address.addressComponents?.find((component) => component.componentType === "postal_code");
    if ((postBox === undefined && (streetNumber === undefined || route === undefined)) ||
        (locality === undefined && subLocality === undefined && neighborhood === undefined) ||
        administrativeAreaLevel1 === undefined ||
        postalCode === undefined)
        return;
    if ((postBox &&
        ![
            GoogleMaps.AddressComponentsEntityConfirmationLevel.CONFIRMED,
            GoogleMaps.AddressComponentsEntityConfirmationLevel.UNCONFIRMED_BUT_PLAUSIBLE,
        ].includes(postBox.confirmationLevel)) ||
        (streetNumber &&
            ![
                GoogleMaps.AddressComponentsEntityConfirmationLevel.CONFIRMED,
                GoogleMaps.AddressComponentsEntityConfirmationLevel.UNCONFIRMED_BUT_PLAUSIBLE,
            ].includes(streetNumber.confirmationLevel)) ||
        (route &&
            route.confirmationLevel !== GoogleMaps.AddressComponentsEntityConfirmationLevel.CONFIRMED) ||
        (subpremise &&
            ![
                GoogleMaps.AddressComponentsEntityConfirmationLevel.CONFIRMED,
                GoogleMaps.AddressComponentsEntityConfirmationLevel.UNCONFIRMED_BUT_PLAUSIBLE,
            ].includes(subpremise.confirmationLevel)) ||
        (locality &&
            locality.confirmationLevel !==
                GoogleMaps.AddressComponentsEntityConfirmationLevel.CONFIRMED) ||
        (subLocality &&
            subLocality.confirmationLevel !==
                GoogleMaps.AddressComponentsEntityConfirmationLevel.CONFIRMED) ||
        (neighborhood &&
            neighborhood.confirmationLevel !==
                GoogleMaps.AddressComponentsEntityConfirmationLevel.CONFIRMED) ||
        administrativeAreaLevel1.confirmationLevel !==
            GoogleMaps.AddressComponentsEntityConfirmationLevel.CONFIRMED ||
        postalCode.confirmationLevel !== GoogleMaps.AddressComponentsEntityConfirmationLevel.CONFIRMED)
        return;
    const validatedAddress = {
        street: (postBox?.componentName.text ??
            `${streetNumber.componentName.text} ${route.componentName.text}${subpremise ? ` ${subpremise.componentName.text}` : ""}`),
        city: (locality?.componentName.text ?? subLocality?.componentName.text ?? neighborhood.componentName.text),
        state: administrativeAreaLevel1.componentName.text,
        zip: postalCode.componentName.text,
    };
    if (commonLibraryContext.setValidAddressCacheFn) {
        await commonLibraryContext.setValidAddressCacheFn(address, validatedAddress);
    }
    return validatedAddress;
};
const validAddressSchema = z
    .any()
    .transform(async (val) => {
    let unvalidatedAddress;
    if (typeof val === "string") {
        if (val.length === 0) {
            return undefined;
        }
        unvalidatedAddress = val;
    }
    else {
        const parsedAddress = partialAddressSchema.safeParse(val);
        if (parsedAddress.success) {
            unvalidatedAddress = `${parsedAddress.data.street} ${parsedAddress.data.city}, ${parsedAddress.data.state} ${parsedAddress.data.zip}`;
        }
        else {
            return undefined;
        }
    }
    const validatedAddress = await getValidatedAddress(unvalidatedAddress);
    if (validatedAddress !== undefined)
        return validatedAddress;
    return undefined;
})
    .refine((val) => val !== undefined, "Invalid address")
    .brand("ValidAddress");
const days = z.number().brand();
const weeks = z.number().brand();
const months = z.number().brand();
const years = z.number().brand();
const calendarDay = z.object({
    month: z.number().min(1).max(12),
    day: z.number().min(1).max(31),
});
const fsr = z.number().positive().min(1).max(999).brand();
const fico = z.number().positive().min(1).max(999).brand();
var ProductType;
(function (ProductType) {
    ProductType["SURETY"] = "surety";
})(ProductType || (ProductType = {}));

export { GoogleMaps, ProductType, additionalIndemnitorRiderId, addressSchema, agencyId, agentId, attachmentId, authzId, bidSuretyBondFormId, bidSuretyBondId, bidSuretyBondIssuanceId, calendarDay, carrierAgencyRelationshipId, carrierContractSuretyDetailsId, carrierName, carrierSuretyDetailsId, city, commentId, companyContactId, companyCreditReportId, contractSuretyBondFormId, contractSuretyBondId, contractSuretyBondIssuanceId, contractSuretyCarrierAgencyRelationshipId, days, decrypted, decryptedSSN, eSignatureId, eSignatureVendorId, email, encrypted, entityId, entityName, eventId, fein, fico, fileId, filePreviewId, fileUploadId, financialStatementId, firstName, followerId, fsr, getValidatedAddress, hashed, indemnificationId, indemnitorReleaseId, indemnityWaiverId, individualContactId, individualCreditReportId, individualOFACCheckId, lastName, months, npn, partialAddressSchema, paymentId, paymentVendorName, phoneNumber, ssn, streetAddress, suretyAccountCarrierBondingLineId, suretyAccountId, suretyAccountReviewReasonId, suretyAccountUnderwritingId, suretyBondFormFileUploadId, suretyBondFormId, suretyBondFormTemplateId, suretyBondId, suretyBondIssuanceId, suretyBondNumberCacheId, suretyBondNumberCounterId, suretyBondPeriodId, suretyBondRenewalPeriodTemplateId, suretyCarrierAgencyRelationshipId, suretyCarrierPoaId, suretyCompanyBondingLineId, suretyIndemnityAgreementId, suretyQuoteDraftBondFormFileUploadId, suretyQuoteId, suretyQuoteReviewReasonId, validAddressSchema, weeks, workInProgressStatementId, years, zipCode };
