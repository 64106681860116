import { CompanyContactId, SuretyAccountId, formatToDollar } from "@inrev/common";
import { useMemo, useState } from "react";
import {
	HiArrowDownTray,
	HiOutlineBuildingOffice,
	HiOutlineClipboard,
	HiOutlineCurrencyDollar,
	HiOutlineMapPin,
	HiOutlineShieldCheck,
} from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { LoadingModal } from "../../../../components/layout/LoadingModal";
import { Button } from "../../../../components/ui/Button";
import { FollowerControls } from "../../../../components/ui/FollowerControls";
import { Separator } from "../../../../components/ui/Separator";
import { getStatusTagByStatus } from "../../../../components/ui/StatusTag";
import { SubmittedSuretyAccount } from "../../../../domain/agent/account/types";
import { getAccountPrimary } from "../../../../domain/agent/account/utils";
import { useCreateBondRequestDraft } from "../../../../domain/agent/request/api";
import { BondRequest } from "../../../../domain/agent/request/types";
import { useFetchAgentPreviews } from "../../../../domain/agent/user/api";
import { useFollowers } from "../../../../domain/shared/followers/api";
import { formatAddress } from "../../../../utils";
import { AccountCompaniesModal } from "../status/underwritten/AccountCompaniesModal";

export type AccountHeaderProps = {
	account: SubmittedSuretyAccount;
};

export const AccountHeader = ({ account }: AccountHeaderProps) => {
	const navigate = useNavigate();
	const { createBondRequest, createBondRequestIsLoading } = useCreateBondRequestDraft();
	const followersControl = useFollowers("accounts", account.id);
	const isBondingLineAvailable =
		account.contract?.bondingLine !== undefined &&
		account.contract.bondingLine.singleLimit !== undefined &&
		account.contract.bondingLine.aggregateLimit !== undefined;
	const [companiesModalOpen, setCompaniesModalOpen] = useState<boolean>(false);
	const [showRequestButton, setShowRequestButton] = useState<boolean>(true);
	const [showPrequalButton, setShowPrequalButton] = useState<boolean>(isBondingLineAvailable);

	const primary = useMemo(() => {
		const primary = getAccountPrimary(account);
		if (!primary) throw new Error();
		return primary;
	}, [account.primaryCompanyId, account.companies]);
	const totalExposure = useMemo(() => {
		return account.contract.exposure.quotes + account.contract?.exposure.bonds;
	}, [account.contract?.exposure]);

	const onRequestCreateSuccess = (data: BondRequest) => navigate(`/requests/${data.id}`);

	const handleHeaderRequestButtonClick = () => {
		if (account.companies.length === 1) {
			createBondRequest(
				{
					principalCompanyId: account.companies[0].id as CompanyContactId,
					accountId: account.id as SuretyAccountId,
				},
				{ onSuccess: onRequestCreateSuccess },
			);
		} else {
			setShowRequestButton(true);
			setShowPrequalButton(false);
			setCompaniesModalOpen(true);
		}
	};

	const handleModalRequestButtonClick = (id: string) => {
		setCompaniesModalOpen(false);
		createBondRequest(
			{ principalCompanyId: id as CompanyContactId, accountId: account.id as SuretyAccountId },
			{ onSuccess: onRequestCreateSuccess },
		);
	};

	return (
		<>
			<div className="flex w-full space-x-[120px] mt-[28px]">
				<div className="flex flex-col flex-1 space-y-[18px]">
					{getStatusTagByStatus(account.status)}
					<div className="flex flex-col space-y-[14px]">
						<div className="flex items-start space-x-[16px]">
							{/* <HiOutlineBuildingOffice2 className="mt-[4px] text-[24px] min-h-[24px] w-[24px] min-w-[24px] text-gray-600" /> */}
							<span className="text-gray-900 text-[25px] font-semibold leading-[32px]">
								{account.displayName}
							</span>
						</div>
						<div className="flex flex-col space-y-[7px] pl-[2px] text-[14.65px] font-[450] text-gray-700 leading-[18px]">
							<div className="flex items-center space-x-[8px]">
								<HiOutlineMapPin className="text-[16px] stroke-[1.75] text-gray-500" />
								<span>{formatAddress(primary.address)}</span>
							</div>
							<div className="flex items-center space-x-[12px]">
								<div className="flex items-center space-x-[8px]">
									<HiOutlineBuildingOffice className="text-[16px] stroke-[1.75] text-gray-500" />
									<span>
										{account.companies.length} Compan{account.companies.length === 1 ? "y" : "ies"}
									</span>
								</div>
								<Separator orientation="vertical" className="bg-gray-200" />
								<span
									className="text-[13px] text-gray-500 hover:underline hover:text-opacity-100 cursor-pointer"
									onClick={() => {
										setShowRequestButton(true);
										setShowPrequalButton(isBondingLineAvailable);
										setCompaniesModalOpen(true);
									}}
								>
									View Compan{account.companies.length === 1 ? "y" : "ies"}
								</span>
							</div>
							<div className="flex flex-col gap-y-[16px]">
								<div className="flex items-center gap-[12px]">
									<div className="flex items-center space-x-[8px] shrink-0">
										<HiOutlineClipboard className="text-[16px] stroke-[1.75] text-gray-500" />
										<span>{formatToDollar(account.contract?.exposure.quotes)}&nbsp; Requested</span>
									</div>
									<Separator orientation="vertical" className="bg-gray-200" />
									<div className="flex items-center space-x-[8px] shrink-0">
										<HiOutlineShieldCheck className="text-[16px] stroke-[1.75] text-gray-500" />
										<span>{formatToDollar(account.contract?.exposure.bonds)}&nbsp; Issued</span>
									</div>
									{account.contract?.bondingLine?.aggregateLimit !== undefined && (
										<>
											<Separator orientation="vertical" className="bg-gray-200" />
											<div className="flex items-center space-x-[8px] shrink-0">
												<HiOutlineCurrencyDollar className="text-[16px] stroke-[1.75] text-gray-500" />
												<span>
													{formatToDollar(
														account.contract.bondingLine.aggregateLimit - totalExposure,
													)}
													&nbsp; Remaining
												</span>
											</div>
										</>
									)}
								</div>
								{account.contract?.bondingLine?.aggregateLimit !== undefined && (
									<div className="flex h-[15px] w-full border-[1px] border-gray-500 rounded-[3px] p-[2px] shadow-sm">
										<div
											className="bg-inrev-green/90 h-full"
											style={{
												width: `${(Math.min(totalExposure / account.contract.bondingLine.aggregateLimit, 1) * 100).toFixed(2)}%`,
											}}
										></div>
									</div>
								)}
							</div>
						</div>
					</div>
					<FollowerControls
						{...followersControl}
						useFetchAgentPreviews={useFetchAgentPreviews}
						notFollowingLabel="Follow this account"
						manageFollowersSubtext="Followers will receive email notifications for all comments and changes to the status of this account"
					/>
				</div>
				<div className="flex flex-col space-y-[12px] w-[165px] max-w-[165px]">
					<Button color={"light-blue"} rounded shadow onClick={handleHeaderRequestButtonClick}>
						Request a Bond
					</Button>
					{showPrequalButton && (
						<Button
							color="light-gray"
							rounded
							shadow
							thinFont
							onClick={() => {
								setShowPrequalButton(true);
								setShowRequestButton(false);
								setCompaniesModalOpen(true);
							}}
						>
							<span className="flex items-center space-x-[6.5px]">
								<HiArrowDownTray className="text-[16px] stroke-[.6] text-gray-800" />
								<span>Prequal Letter</span>
							</span>
						</Button>
					)}
				</div>
			</div>
			{companiesModalOpen && (
				<AccountCompaniesModal
					account={account}
					onRequestButtonClick={handleModalRequestButtonClick}
					onClose={() => setCompaniesModalOpen(false)}
					options={{ showRequestButton, showPrequalButton }}
				/>
			)}
			{createBondRequestIsLoading && <LoadingModal />}
		</>
	);
};
