import {
	ContractSuretyType,
	FileType,
	type IndemnityAgreementSignatureMethod,
	SuretyBondClosedReason,
	type SuretyBondFormType,
	SuretyBondIssuanceMethod,
	type SuretyBondType,
	defaultAttachmentTypeLabelMap,
} from "@inrev/common";
import { pick } from "rambda";
import { ReactElement, type ReactNode } from "react";
import { HiOutlineMail } from "react-icons/hi";
import accelerantLogoSm1_5x from "./assets/logos/accelerant-logo-icon-25h@1.5x.png";
import {
	default as accelerantLogoSm,
	default as accelerantLogoSm2x,
} from "./assets/logos/accelerant-logo-icon-25h@2x.png";
import lnicLogoSm1_5x from "./assets/logos/lnic-logo-icon-25h@1.5x.png";
import lnicLogoSm2x from "./assets/logos/lnic-logo-icon-25h@2x.png";
import lnicLogoSm from "./assets/logos/lnic-logo-sm@2x.png";

export const ADMIN_BASE_URL = "/v2/admin";
export const AGENT_BASE_URL = "/v2";

export enum ContractDamage {
	CONSEQUENTIAL = "consequential",
	PRIME_CONTRACT = "prime_contract",
	LIQUIDATED_GT_5000 = "liquidated_gt_5000",
	OTHER = "other",
}

export enum ContractHazard {
	DESIGN_BUILD = "design_build",
	ENVIRONMENTAL_OR_REMEDIATION = "environmental_or_remediation",
	SUPERFUND = "superfund",
	EFFICIENCY_GUARANTEES = "efficiency_guarantees",
	SOVEREIGN_NATION_OWNER = "sovereign_nation_owner",
	RENEWABLE = "renewable",
	TAKEOVER = "takeover",
}

export const accountAttachmentTypeLabelMap: Partial<Record<FileType, string>> = pick(
	[
		"company_credit_report",
		"company_ownership_chart",
		"fye_company_ap_aging",
		"fye_company_ar_aging",
		"fye_company_balance_sheet",
		"fye_company_cash_flow_statement",
		"fye_company_income_statement",
		"individual_credit_report",
		"individual_financial_statement",
		"interim_company_ap_aging",
		"interim_company_ar_aging",
		"interim_company_balance_sheet",
		"interim_company_cash_flow_statement",
		"interim_company_income_statement",
		"wip",
		"other",
		"other_company_financial_statement",
		"other_fye_company_financial_statement",
		"other_interim_company_financial_statement",
	],
	defaultAttachmentTypeLabelMap,
);

export enum USStateOrTerritoryName {
	AL = "Alabama",
	AK = "Alaska",
	AZ = "Arizona",
	AR = "Arkansas",
	CA = "California",
	CO = "Colorado",
	CT = "Connecticut",
	DE = "Delaware",
	DC = "Washington DC",
	FL = "Florida",
	GA = "Georgia",
	HI = "Hawaii",
	ID = "Idaho",
	IL = "Illinois",
	IN = "Indiana",
	IA = "Iowa",
	KS = "Kansas",
	KY = "Kentucky",
	LA = "Louisiana",
	ME = "Maine",
	MD = "Maryland",
	MA = "Massachusetts",
	MI = "Michigan",
	MN = "Minnesota",
	MS = "Mississippi",
	MO = "Missouri",
	MT = "Montana",
	NE = "Nebraska",
	NV = "Nevada",
	NH = "New Hampshire",
	NJ = "New Jersey",
	NM = "New Mexico",
	NY = "New York",
	NC = "North Carolina",
	ND = "North Dakota",
	OH = "Ohio",
	OK = "Oklahoma",
	OR = "Oregon",
	PA = "Pennsylvania",
	RI = "Rhode Island",
	SC = "South Carolina",
	SD = "South Dakota",
	TN = "Tennessee",
	TX = "Texas",
	UT = "Utah",
	VT = "Vermont",
	VA = "Virginia",
	WA = "Washington",
	WV = "West Virginia",
	WI = "Wisconsin",
	WY = "Wyoming",
}

export const usStateOrTerritoryOptions = (
	Object.keys(USStateOrTerritoryName) as (keyof typeof USStateOrTerritoryName)[]
).map((stateKey) => ({ value: stateKey, label: USStateOrTerritoryName[stateKey] }));

export const carrierLogoSmallMap: Record<string, ReactElement<HTMLImageElement>> = {
	"lexington-national-for-accelerant": (
		<img
			src={lnicLogoSm}
			srcSet={`${lnicLogoSm1_5x} 1.5x, ${lnicLogoSm2x} 2x`}
			width={20}
			height={20}
		/>
	),
	accelerant: (
		<img
			src={accelerantLogoSm}
			srcSet={`${accelerantLogoSm1_5x} 1.5x, ${accelerantLogoSm2x} 2x`}
			width={23}
		/>
	),
};

export const financialsPreparationMethodOptions = [
	{ value: "internal", label: "Internal" },
	{ value: "cpa_comp", label: "CPA Compilation" },
	{ value: "cpa_review", label: "CPA Review" },
	{ value: "cpa_audit", label: "CPA Audit" },
	{ value: "does_not_keep", label: "Does not keep financial statements" },
];

export const exposureSizeOptions = [
	{ value: "small", label: "Up to $500k" },
	{ value: "medium", label: "$500k - $1M" },
	{ value: "large", label: "More than $1M" },
];

export const bondRequestIssuanceMethodTypeLabelMap: Record<SuretyBondIssuanceMethod, string> = {
	agent: "Agent Issue",
	electronic: "Electronic copy",
	physical: "inRev Issue: Wet Signatures & Raised Seals",
};

export const agentUserRoleTypeLabelMap: Record<string, string> = {
	"agent-admin": "Agent Admin",
	agent: "Agent",
};

export const contractDamageOptions = [
	{
		value: ContractDamage.CONSEQUENTIAL,
		label: "Consequential Damages",
		tooltip:
			"Consequential Damages are indirect costs incurred by the Obligee as a result of the contractor’s delay to the project<br/><br/>Examples are: lost revenue, lost profit, etc.",
	},
	{ value: ContractDamage.PRIME_CONTRACT, label: "Prime Contract Damages" },
	{
		value: ContractDamage.LIQUIDATED_GT_5000,
		label: "Liquidated damages greater than $5,000",
		tooltip:
			"Liquidated Damages are the daily penalties the contractor must pay as a result of causing a delay to the project",
	},
	{ value: ContractDamage.OTHER, label: "Other damages / I’m unsure" },
];

export const contractHazardOptions = [
	{
		value: ContractHazard.DESIGN_BUILD,
		label: "Design-build",
		tooltip:
			"Design Build occurs when the Principal is responsible for creating the project specs and stamping the design drawings",
	},
	{
		value: ContractHazard.ENVIRONMENTAL_OR_REMEDIATION,
		label: "Environmental or remediation work",
	},
	{ value: ContractHazard.SUPERFUND, label: "Work at a superfund site" },
	{
		value: ContractHazard.EFFICIENCY_GUARANTEES,
		label: "Efficiency guarantees",
		tooltip:
			"Efficiency Guarantees are present when the Principal is responsible for specific system performance<br/><br/>Examples are: uptime, reliability, output, energy usage/output, etc.)",
	},
	{ value: ContractHazard.SOVEREIGN_NATION_OWNER, label: "Work for a sovereign nation owner" },
	{ value: ContractHazard.RENEWABLE, label: "Renewable contract" },
	{ value: ContractHazard.TAKEOVER, label: "Takeover of an existing contract" },
];

export const obligeeRoleOptions = [
	{ value: "project_owner", label: "Project Owner" },
	{ value: "lender", label: "Lender" },
	{ value: "gc", label: "General Contractor" },
	{ value: "other", label: "Other" },
];

export const suretyBondClosedReasonTypeLabelMap: Record<SuretyBondClosedReason, string> = {
	bid_lost: "Bid lost",
	bid_won: "Bid won",
	expired: "Term expired",
	input_error: "Input error",
	non_payment: "Premium non-payment",
	not_awarded: "Project not awarded",
	other: "Other",
	project_completed: "Project completed",
	replaced: "Replaced by another bond",
	returned_by_obligee: "Returned by obligee",
};

export const bondRequestTypeLabelMap: Record<ContractSuretyType, string> = {
	bid: "Bid",
	bid_to_final: "Bid to Final",
	final: "Final",
};

export const bondTypeLabelMap: Record<SuretyBondType, string> = {
	bid: "Bid",
	final: "Final",
	subdivision: "Subdivision",
	maintenance: "Maintenance",
};

export const bondFormTypeLabelMap: Record<SuretyBondFormType, string> = {
	bid: "Bid",
	pnp: "Performance & Payment",
	performance: "Performance",
	payment: "Payment",
	supply: "Supply",
};

export const indemnityAgreementSignatureMethodTypeLabelMap: Record<
	IndemnityAgreementSignatureMethod,
	string
> = {
	electronic: "Electronic Signatures",
	physical: "Wet Signatures",
};

export const indemnityAgreementSignatureMethodTypeDisplayMap: Record<
	IndemnityAgreementSignatureMethod,
	ReactNode
> = {
	electronic: (
		<div className="grid grid-cols-[auto_1fr] grid-rows-2 gap-x-[10px]">
			<HiOutlineMail className="self-center text-[16px]" />
			<span>{indemnityAgreementSignatureMethodTypeLabelMap["electronic"]}</span>
			<span></span>
			<span className="text-[13px] text-gray-600 italic">Sent via Docusign</span>
		</div>
	),
	physical: "Wet Signatures",
};
