import { AttachmentFile } from "@inrev/common";
import { useContext, useState } from "react";
import { AttachmentsListSection } from "../../../../../components/layout/AttachmentsListSection";
import { CommentsSection } from "../../../../../components/layout/CommentsSection";
import { WorkflowLayout } from "../../../../../components/layout/WorkflowLayout";
import { Icon } from "../../../../../components/ui/Icon";
import { Separator } from "../../../../../components/ui/Separator";
import { accountAttachmentTypeLabelMap } from "../../../../../constants";
import { SubmittedSuretyAccount } from "../../../../../domain/agent/account/types";
import { SuretyAccountCommentsContext } from "../../AccountView";
import { AccountBondsTable } from "../../shared/AccountBondsTable";
import { AccountHeader } from "../../shared/AccountHeader";
import { AccountRequestsTable } from "../../shared/AccountRequestsTable";

type AccountReviewViewProps = {
	account: SubmittedSuretyAccount;
};

export const AccountReviewView = ({ account }: AccountReviewViewProps) => {
	const [attachments, setAttachments] = useState<AttachmentFile[]>(account.attachments);
	const { comments, commentsLoading, createComment, createCommentLoading } = useContext(
		SuretyAccountCommentsContext,
	);

	return (
		<WorkflowLayout title={account.displayName}>
			<div className="w-[785px] max-w-[785px] min-w-[785px]">
				<div className="flex flex-col space-y-[40px] pb-[125px]">
					<AccountHeader account={account} />
					<div className="w-full h-fit flex flex-col space-y-[12px] !mt-[32px]">
						<div className="min-h-fit px-[25px] py-[12px] flex items-center justify-center space-x-[12px] bg-gray-50 rounded-md border border-gray-200 shadow-sm">
							<Icon type="headset" className="fill-gray-600 h-[18px]" />
							<Separator orientation="vertical" className="h-[20px] w-[1.5px] bg-gray-300" />
							<span className="text-[13px] text-gray-600 font-[475] italic">
								An underwriter is taking a closer look at this account and may reach out with
								additional questions.
							</span>
						</div>
						<div className="flex flex-col w-full h-fit min-h-fit bg-gray-50 shadow-sm border border-gray-200 divide-y divide-y-gray-200 rounded-md">
							<div className="w-full h-fit min-h-fit flex flex-col space-y-[18px] p-[40px] pt-[25px]">
								<span className="text-[18px] text-gray-900 font-medium">What caused a review?</span>
								<ol className="flex flex-col space-y-[8px] px-[12px] text-gray-900 text-[14px] font-normal list-inside">
									{Array.from(
										new Set(account.contract.underwriting.reviewReasons.map((r) => r.description)),
									).map((description, index) => (
										<li key={index}>{description}</li>
									))}
								</ol>
							</div>
							{new Set(account.contract.underwriting.supplementalRequirements).size > 0 ? (
								<div className="w-full h-fit min-h-fit flex flex-col space-y-[18px] px-[40px] pt-[25px] pb-[30px]">
									<div className="flex-1 flex items-center space-x-[10px]">
										<Icon type="action-required" className="h-[20px]" />
										<span className="text-[16px] font-medium">Action Required</span>
									</div>
									<div className="w-full flex flex-col space-y-[10px] pl-[14px]">
										<span className="text-[14px] font-medium">
											Please ensure the following additional documents are attached:
										</span>
										<ol className="flex flex-col space-y-[4px] list-disc text-gray-900 text-[14px] font-normal list-inside">
											{Array.from(
												new Set(account.contract.underwriting.supplementalRequirements),
											).map((requirement, index) => (
												<li key={index}>{requirement}</li>
											))}
										</ol>
									</div>
								</div>
							) : undefined}
						</div>
					</div>
					<AccountRequestsTable account={account} />
					<AccountBondsTable account={account} />
					<CommentsSection
						comments={comments}
						commentsLoading={commentsLoading}
						createComment={createComment}
						createCommentLoading={createCommentLoading}
						commentDownloadHeader={`Account For ${account.displayName}`}
					/>
					<AttachmentsListSection
						attachments={attachments}
						upload={{
							url: `/v2/surety/accounts/${account.id}/attachments`,
							onChange: (val) => setAttachments(val),
							allowedTypesAndLabels: accountAttachmentTypeLabelMap,
							invalidateQueryKeys: [["suretyAccounts", account.id]],
						}}
						download={{
							baseUrl: `/v2/surety/accounts/${account.id}/attachments`,
							baseQueryKey: "accountAttachments",
						}}
						typeLabelMap={accountAttachmentTypeLabelMap}
					/>
				</div>
			</div>
		</WorkflowLayout>
	);
};
