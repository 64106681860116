import {
	AttachmentFile,
	FinancialStatementId,
	financialStatementAttachmentTypeLabelMap,
	formatToDollar,
	formatYesNo,
} from "@inrev/common";
import { DateTime } from "luxon";
import { SetStateAction, useEffect, useMemo, useState } from "react";
import { HiOutlineTrash, HiPlus } from "react-icons/hi";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { AttachmentsListSection } from "../../../../components/layout/AttachmentsListSection";
import { ConfirmationModalClickWrapper } from "../../../../components/layout/ConfirmationModalClickWrapper";
import { Button } from "../../../../components/ui/Button";
import { CardGrayBody } from "../../../../components/ui/CardGrayBody";
import { LineItem } from "../../../../components/ui/LineItem";
import { AdminSuretyAccount } from "../../../../domain/admin/account/types";
import { useAdminDeleteFinancialStatement } from "../../../../domain/admin/financial-statement/api";
import { formatEmpty } from "../../../../utils";
import { AdminFinancialStatementModal } from "../shared/AdminUpsertFinancialStatementModal";
import { MetricsSection } from "./MetricsSection";

export const FinancialStatementSection = ({ account }: { account: AdminSuretyAccount }) => {
	const [currentFinancialStatementIndex, setCurrentFinancialStatementIndex] = useState<
		number | undefined
	>(undefined);

	const currentFinancialStatement: AdminSuretyAccount["financialStatements"][number] | undefined =
		currentFinancialStatementIndex !== undefined
			? account.financialStatements[currentFinancialStatementIndex]
			: undefined;

	const [currentWipStatementView, setCurrentWipStatementView] = useState<
		AdminSuretyAccount["wipStatements"][number] | undefined
	>();

	const [financialStatementEditorOpen, setFinancialStatementEditorOpen] = useState<boolean>(false);
	const [selectedFinancialStatementId, setSelectedFinancialStatementId] = useState<
		FinancialStatementId | undefined
	>(undefined);

	useEffect(() => {
		account.financialStatements.length
			? setCurrentFinancialStatementIndex(0)
			: setCurrentFinancialStatementIndex(undefined);
	}, [account.financialStatements]);
	const { deleteFinancialStatement } = useAdminDeleteFinancialStatement(account.id);

	useEffect(() => {
		if (selectedFinancialStatementId) setFinancialStatementEditorOpen(true);
	}, [selectedFinancialStatementId]);

	useEffect(() => {
		if (!financialStatementEditorOpen) {
			setSelectedFinancialStatementId(undefined);
		}
	}, [financialStatementEditorOpen]);

	useEffect(() => {
		if (currentFinancialStatement !== undefined) {
			setCurrentWipStatementView(
				account.wipStatements.find((wip) => wip.date === currentFinancialStatement.date),
			);
		}
	}, [currentFinancialStatement]);

	const financialStatementYears = useMemo(
		() =>
			account.financialStatements.map((statement) => DateTime.fromISO(statement.date).toUTC().year),
		[account.financialStatements],
	);

	return (
		<>
			<div className="w-full flex flex-col space-y-[40px]">
				<div className="text-[20px] !my-[-10px] font-medium">Financials</div>
				<CardGrayBody
					header={<div className="!my-[-10px] !mx-[-8px]">YTD</div>}
					contentClassName="py-[20px] px-[30px] space-y-[12px]"
				>
					<div className="flex flex-col space-y-[15px]">
						<LineItem label="Profitable YTD" className="text-[14px]" labelClassName="text-[14px]">
							{formatEmpty(
								formatYesNo(
									(() => {
										if (account.financialStatements.length > 0) {
											if (
												DateTime.fromISO(account.financialStatements[0].date).plus({
													years: 1,
												}) >= DateTime.now().startOf("day")
											) {
												return account.financialStatements[0].profitable;
											}
										}
										if (
											DateTime.fromISO(account.contract.underwriting.createdAt).year ===
											DateTime.now().year
										) {
											return account.contract.underwriting.input.financials.profitableYtd;
										}
									})(),
								),
							)}
						</LineItem>
					</div>
				</CardGrayBody>
				{account.financialStatements.length > 0 && (
					<div className="flex items-center space-x-[10px]">
						{financialStatementYears.map((year, index) => {
							if (index === currentFinancialStatementIndex) {
								return (
									<div
										key={index}
										className="px-[10px] py-[6px] cursor-pointer bg-gray-100 border border-gray-200 rounded-md"
										onClick={() => setCurrentFinancialStatementIndex(index)}
									>
										{year}
									</div>
								);
							} else {
								return (
									<div
										key={index}
										className="px-[10px] py-[6px] cursor-pointer rounded-md hover:bg-gray-50"
										onClick={() => setCurrentFinancialStatementIndex(index)}
									>
										{year}
									</div>
								);
							}
						})}
					</div>
				)}
				<div className="w-full flex flex-col space-y-[30px]">
					<div className="text-[17px] !my-[-10px] font-medium italic text-gray-500">Statements</div>
					<Button
						onClick={() => setFinancialStatementEditorOpen(true)}
						className="ml-[-5px] w-fit"
						color="gray"
						rounded
						filled
						thinFont
					>
						<div className="flex min-w-fit items-center space-x-[5px]">
							<HiPlus className="text-[20px] text-gray-800" />
							<div className="min-w-fit">Add Financial Statement</div>
						</div>
					</Button>
					{financialStatementEditorOpen && (
						<AdminFinancialStatementModal
							account={account}
							selectedFinancialStatementId={selectedFinancialStatementId}
							onClose={() => setFinancialStatementEditorOpen(false)}
						/>
					)}
					{account.financialStatements.length > 0 && (
						<>
							<_FinancialStatementDisplay
								account={account}
								currentFinancialStatement={currentFinancialStatement}
								setSelectedFinancialStatementId={setSelectedFinancialStatementId}
								deleteFinancialStatement={deleteFinancialStatement}
							/>
						</>
					)}
				</div>
				<_WipDisplay currentWipStatementView={currentWipStatementView} />
			</div>
			<MetricsSection currentFinancialStatement={currentFinancialStatement} />
		</>
	);
};

const _FinancialStatementAttachmentSection = ({
	account,
	currentFinancialStatement,
}: {
	account: AdminSuretyAccount;
	currentFinancialStatement: AdminSuretyAccount["financialStatements"][number] | undefined;
}) => {
	const [attachments, setAttachments] = useState<AttachmentFile[]>(
		currentFinancialStatement?.attachments ?? [],
	);
	useEffect(() => {
		setAttachments(currentFinancialStatement?.attachments ?? []);
	}, [currentFinancialStatement]);

	if (!currentFinancialStatement) return <></>;
	return (
		<div className="flex flex-col space-y-[15px] flex-1">
			<div className="text-[15px] text-gray-500 italic mb-[-15px]">
				Financial Statement Attachments
			</div>
			<AttachmentsListSection
				header=""
				attachments={attachments}
				typeLabelMap={financialStatementAttachmentTypeLabelMap}
				upload={{
					url: `/v2/admin/surety/accounts/${account.id}/financial-statements/${currentFinancialStatement.id}/attachments`,
					onChange: (val) => setAttachments(val),
					allowedTypesAndLabels: financialStatementAttachmentTypeLabelMap,
					invalidateQueryKeys: [["adminSuretyAccounts", account.id]],
				}}
				download={{
					baseUrl: `/v2/admin/surety/financial-statements/${currentFinancialStatement.id}/attachments`,
					baseQueryKey: "adminAccountAttachments",
				}}
			/>
		</div>
	);
};

const _FinancialStatementDisplay = ({
	account,
	currentFinancialStatement,
	setSelectedFinancialStatementId,
	deleteFinancialStatement,
}: {
	account: AdminSuretyAccount;
	currentFinancialStatement: AdminSuretyAccount["financialStatements"][number] | undefined;
	setSelectedFinancialStatementId: (
		value: SetStateAction<FinancialStatementId | undefined>,
	) => void;
	deleteFinancialStatement: (value: any) => void;
}) => {
	return (
		<div className="flex space-x-[10px]">
			<div className="flex-1">
				<CardGrayBody
					header={
						<div className="justify-between flex items-center space-x-[10px]">
							<span className="text-[15px] font-medium">Financial Statement</span>
							<div className="flex items-right space-x-[8px] w-fit h-fit">
								<Button
									color="gray"
									className="border-0 w-fit h-fit p-2"
									onClick={() => {
										if (currentFinancialStatement !== undefined) {
											setSelectedFinancialStatementId(currentFinancialStatement.id);
										}
									}}
								>
									<HiOutlinePencilSquare className="text-[20px] text-gray-600" />
								</Button>

								<ConfirmationModalClickWrapper
									message={
										<div className="w-[375px] text-center break-words">
											Are you sure you want to delete this financial statement?
										</div>
									}
									confirmButtonText="Delete"
									className="items-center w-fit h-fit"
									confirmationButtonClassName="border-[1px] border-red-500 bg-white text-red-500 hover:bg-red-500 hover:bg-opacity-10"
									onConfirm={() => {
										if (currentFinancialStatement !== undefined) {
											deleteFinancialStatement(currentFinancialStatement.id);
										}
									}}
								>
									<HiOutlineTrash className="text-[20px] stroke-[2px] text-red-400 hover:text-red-600 cursor-pointer w-fit h-fit mt-2" />
								</ConfirmationModalClickWrapper>
							</div>
						</div>
					}
					contentClassName="py-[25px] px-[30px] space-y-[30px]"
				>
					<div className="flex flex-col space-y-[15px] flex-1">
						<div className="text-[15px] text-gray-500 italic">Statement Details</div>
						<div className="flex flex-col space-y-[12px]">
							<LineItem
								label="Statement Preparation Method"
								className="text-[14px]"
								labelClassName="text-[14px]"
							>
								{formatEmpty(currentFinancialStatement?.preparationMethod)}
							</LineItem>
							<LineItem label="Statement Date" className="text-[14px]" labelClassName="text-[14px]">
								{formatEmpty(
									currentFinancialStatement?.date !== undefined
										? DateTime.fromISO(currentFinancialStatement.date)
												.toUTC()
												.toLocaleString(DateTime.DATE_MED)
										: undefined,
								)}
							</LineItem>
						</div>
					</div>
					<div className="flex flex-col space-y-[15px] flex-1">
						<div className="text-[15px] text-gray-500 italic">Assets</div>
						<div className="flex flex-col space-y-[12px]">
							<LineItem label="Cash" className="text-[14px]" labelClassName="text-[14px]">
								{formatEmpty(formatToDollar(currentFinancialStatement?.corporateCash))}
							</LineItem>
							<LineItem
								label="A/Rs less than 90 days"
								className="text-[14px]"
								labelClassName="text-[14px]"
							>
								{formatEmpty(formatToDollar(currentFinancialStatement?.accountsReceivable))}
							</LineItem>
							<LineItem label="Underbillings" className="text-[14px]" labelClassName="text-[14px]">
								{formatEmpty(formatToDollar(currentFinancialStatement?.underbillings))}
							</LineItem>
							<LineItem label="Current Assets" className="text-[14px]" labelClassName="text-[14px]">
								{formatEmpty(formatToDollar(currentFinancialStatement?.currentAssets))}
							</LineItem>
							<LineItem label="Total Assets" className="text-[14px]" labelClassName="text-[14px]">
								{formatEmpty(formatToDollar(currentFinancialStatement?.totalAssets))}
							</LineItem>
						</div>
					</div>
					<div className="flex flex-col space-y-[15px] flex-1">
						<div className="text-[15px] text-gray-500 italic">Liabilities</div>
						<div className="flex flex-col space-y-[12px]">
							<LineItem
								label="Accounts Payable"
								className="text-[14px]"
								labelClassName="text-[14px]"
							>
								{formatEmpty(formatToDollar(currentFinancialStatement?.accountsPayable))}
							</LineItem>
							<LineItem
								label="Credit Cards Balance"
								className="text-[14px]"
								labelClassName="text-[14px]"
							>
								{formatEmpty(formatToDollar(currentFinancialStatement?.creditCardsPayable))}
							</LineItem>
							<LineItem label="BLOC Balance" className="text-[14px]" labelClassName="text-[14px]">
								{formatEmpty(formatToDollar(currentFinancialStatement?.bankLinePayable))}
							</LineItem>
							<LineItem
								label="Accrued Expenses"
								className="text-[14px]"
								labelClassName="text-[14px]"
							>
								{formatEmpty(formatToDollar(currentFinancialStatement?.accruedExpenses))}
							</LineItem>
							<LineItem label="Overbillings" className="text-[14px]" labelClassName="text-[14px]">
								{formatEmpty(formatToDollar(currentFinancialStatement?.overbillings))}
							</LineItem>
							<LineItem
								label="Current Portion of LTD"
								className="text-[14px]"
								labelClassName="text-[14px]"
							>
								{formatEmpty(formatToDollar(currentFinancialStatement?.currentPortionOfLtd))}
							</LineItem>
							<LineItem
								label="Current Liabilities"
								className="text-[14px]"
								labelClassName="text-[14px]"
							>
								{formatEmpty(formatToDollar(currentFinancialStatement?.currentLiabilities))}
							</LineItem>
							<LineItem label="Term Loan Debt" className="text-[14px]" labelClassName="text-[14px]">
								{formatEmpty(formatToDollar(currentFinancialStatement?.termLoanDebt))}
							</LineItem>
							<LineItem
								label="Total Liabilities"
								className="text-[14px]"
								labelClassName="text-[14px]"
							>
								{formatEmpty(formatToDollar(currentFinancialStatement?.totalLiabilities))}
							</LineItem>
						</div>
					</div>
					<div className="flex flex-col space-y-[15px] flex-1">
						<div className="text-[15px] text-gray-500 italic">Income Statement</div>
						<div className="flex flex-col space-y-[12px]">
							<LineItem label="Revenue" className="text-[14px]" labelClassName="text-[14px]">
								{formatEmpty(formatToDollar(currentFinancialStatement?.revenue))}
							</LineItem>
							<LineItem label="GA Expense" className="text-[14px]" labelClassName="text-[14px]">
								{formatEmpty(formatToDollar(currentFinancialStatement?.gaExpense))}
							</LineItem>
							<LineItem label="Profitable" className="text-[14px]" labelClassName="text-[14px]">
								{formatEmpty(formatYesNo(currentFinancialStatement?.profitable))}
							</LineItem>
						</div>
					</div>
					<div className="flex flex-col space-y-[15px] flex-1">
						<div className="text-[15px] text-gray-500 italic">Bank Details</div>
						<div className="flex flex-col space-y-[12px]">
							<LineItem label="BLOC Size" className="text-[14px]" labelClassName="text-[14px]">
								{formatEmpty(formatToDollar(currentFinancialStatement?.blocSize))}
							</LineItem>
							<LineItem label="BLOC Available" className="text-[14px]" labelClassName="text-[14px]">
								{formatEmpty(formatToDollar(currentFinancialStatement?.calculations.blocAvailable))}
							</LineItem>
						</div>
					</div>
					<_FinancialStatementAttachmentSection
						account={account}
						currentFinancialStatement={currentFinancialStatement}
					/>
				</CardGrayBody>
			</div>
		</div>
	);
};

const _WipDisplay = ({
	currentWipStatementView,
}: { currentWipStatementView: AdminSuretyAccount["wipStatements"][number] | undefined }) => {
	return (
		<div className="flex flex-col space-y-[15px] flex-1">
			<CardGrayBody
				header={<div className="!my-[-10px] !mx-[-8px]">Work In Progress</div>}
				contentClassName="py-[20px] px-[30px] space-y-[12px]"
			>
				<LineItem label="Backlog (CTC)" className="text-[14px]" labelClassName="text-[14px]">
					{formatEmpty(formatToDollar(currentWipStatementView?.projectBacklogCost))}
				</LineItem>
				<LineItem
					label="Gross Profit In Backlog"
					className="text-[14px]"
					labelClassName="text-[14px]"
				>
					{formatEmpty(formatToDollar(currentWipStatementView?.projectBacklogGrossProfit))}
				</LineItem>
			</CardGrayBody>
		</div>
	);
};
