import { z } from 'zod';
import { suretyBondId } from '../../../../opaque.mjs';
import { ContractSuretyType, dateTimeSchema } from '../../../../../enums.mjs';
import { safeNumberSchema, currencySchema } from '../../../../utils.mjs';
import { Underwriting as Underwriting$1 } from './submitted-contract-surety-quote.types.mixins.mjs';

var Underwriting;
(function (Underwriting) {
    Underwriting.schema = z.object({
        quote: Underwriting$1.Quote.baseSchema.extend({
            calculations: z.object({
                daysFromBidToContractDate: safeNumberSchema,
                bidSpread: currencySchema,
                actualToRequestedBidSpread: currencySchema,
                potentialPrincipalAggregateExposure: currencySchema,
            })
        }),
        project: Underwriting$1.Project.schema
    });
})(Underwriting || (Underwriting = {}));
var Details;
(function (Details) {
    Details.schema = z.object({
        contractSuretyType: z.literal(ContractSuretyType.bid_to_final),
        bidBondId: suretyBondId,
        contractAmount: currencySchema,
        secondBid: currencySchema.nullish(),
        contractDate: dateTimeSchema,
        actualBidDate: dateTimeSchema,
        underwriting: Underwriting.schema
    });
})(Details || (Details = {}));

export { Details, Underwriting };
