import { z } from 'zod';
import { nameSuffixSchema, naicsCodeSchema, FileType, SuretyType } from '../../../../../enums.mjs';
import { addressSchema, email, decryptedSSN, encrypted, ssn } from '../../../../opaque.mjs';
import { stringOrEmptyString, boolOrEmptyString, uploadedFileSchema, getSectionSchemaTypeFromData } from '../../../../utils.mjs';

var Data;
(function (Data) {
    let Details;
    (function (Details) {
        let Individual;
        (function (Individual) {
            Individual.schema = z.object({
                type: z.union([z.literal('owner'), z.literal('spouse')]),
                draftId: z.string(),
                firstName: z.string(),
                middleInitial: z.string().toUpperCase().max(1).optional(),
                lastName: z.string(),
                suffix: nameSuffixSchema.optional(),
                address: addressSchema,
                email: email.optional(),
                ssn: decryptedSSN.optional(),
                married: z.boolean(),
                spouseDraftId: z.string().optional(),
                ownsAHome: z.boolean()
            });
            Individual.encryptedSchema = Individual.schema.extend({
                ssn: encrypted(ssn).optional()
            });
        })(Individual = Details.Individual || (Details.Individual = {}));
        let Company;
        (function (Company) {
            Company.schema = z.object({
                draftId: z.string(),
                name: z.string(),
                fein: stringOrEmptyString,
                address: addressSchema
            });
        })(Company = Details.Company || (Details.Company = {}));
        Details.schema = z.object({
            exposureSize: z.union([
                z.literal('small'),
                z.literal('medium'),
                z.literal('large'),
                z.literal('')
            ], { errorMap: () => ({ message: "Please select an exposure size" }) }),
            companies: z.array(Company.schema),
            individuals: z.array(Individual.schema),
            fundNames: z.array(z.string()),
            creditReportPermission: z.array(z.object({
                id: z.string(),
                name: z.string(),
                permission: z.boolean()
            }))
        });
        Details.encryptedSchema = Details.schema.extend({
            individuals: z.array(Individual.encryptedSchema)
        });
    })(Details = Data.Details || (Data.Details = {}));
    let History;
    (function (History) {
        History.schema = z.object({
            naicsCodes: z.array(naicsCodeSchema),
            largestProjectCompleted: stringOrEmptyString,
            failedToCompleteAProject: boolOrEmptyString,
            constructionProjectGrossLoss: boolOrEmptyString,
            currentConstructionProjectGrossLoss: z.union([
                z.boolean(),
                z.literal('')
            ]),
            lostAPaymentSuit: boolOrEmptyString,
            causedSuretyLoss: boolOrEmptyString
        });
    })(History = Data.History || (Data.History = {}));
    let Financials;
    (function (Financials) {
        Financials.fileTypeSchema = z.enum([FileType.fye_company_balance_sheet, FileType.fye_company_income_statement]);
        Financials.schema = z.object({
            outdatedFinancials: boolOrEmptyString,
            companyDraftId: stringOrEmptyString,
            statementDate: stringOrEmptyString,
            preparationMethod: stringOrEmptyString,
            balanceSheet: z.object({
                corporateCash: stringOrEmptyString,
                accountsReceivable: stringOrEmptyString,
                underbillings: stringOrEmptyString,
                currentAssets: stringOrEmptyString,
                totalAssets: stringOrEmptyString,
                accountsPayable: stringOrEmptyString,
                creditCardsPayable: stringOrEmptyString,
                bankLinePayable: stringOrEmptyString,
                accruedExpenses: stringOrEmptyString,
                overbillings: stringOrEmptyString,
                currentPortionOfLtd: stringOrEmptyString,
                currentLiabilities: stringOrEmptyString,
                termLoanDebt: stringOrEmptyString,
                totalLiabilities: stringOrEmptyString
            }),
            incomeStatement: z.object({
                revenue: stringOrEmptyString,
                gaExpense: stringOrEmptyString,
                profitableYtd: boolOrEmptyString,
                profitableLastFye: boolOrEmptyString,
                profitableYearPriorToLastFiscalYear: boolOrEmptyString
            }),
            wip: z.object({
                projectBacklogCost: stringOrEmptyString,
                projectBacklogGrossProfit: stringOrEmptyString
            }),
            bankDetails: z.object({
                blocSize: stringOrEmptyString
            }),
            files: z.array(uploadedFileSchema([FileType.fye_company_balance_sheet, FileType.fye_company_income_statement, FileType.fye_company_ap_aging, FileType.fye_company_ar_aging, FileType.fye_company_cash_flow_statement, FileType.other_fye_company_financial_statement]))
        });
    })(Financials = Data.Financials || (Data.Financials = {}));
    Data.schema = z.object({
        details: Details.schema,
        history: History.schema,
        financials: Financials.schema
    });
    Data.encryptedSchema = Data.schema.extend({
        details: Details.encryptedSchema
    });
})(Data || (Data = {}));
var Schema;
(function (Schema) {
    Schema.schema = getSectionSchemaTypeFromData(Data.schema);
})(Schema || (Schema = {}));
const draftSchema = z.object({
    suretyType: z.literal(SuretyType.contract),
    data: Data.schema,
    schema: Schema.schema,
});
const encryptedDraftSchema = draftSchema.extend({
    data: Data.encryptedSchema
});

export { Data, Schema, draftSchema, encryptedDraftSchema };
