import { AttachmentId, FileId, UploadedFile } from "@inrev/common";
import { HiOutlineArrowDownTray, HiOutlineTrash, HiXMark } from "react-icons/hi2";
import { ConfirmationModalClickWrapper } from "../layout/ConfirmationModalClickWrapper";
import { Button } from "./Button";
import { CustomFileIcon } from "./CustomFileIcon";

type FileUploadItemProps = Omit<UploadedFile, "id"> & {
	id: FileId | AttachmentId;
	typeLabelMap: Partial<Record<string, string>>;
	preventDelete?: boolean | undefined;
	onDelete?: (id: string) => void;
	onDownload?: (id: string) => void;
	deleting?: boolean;
} & (
		| {
				preventDelete: true;
		  }
		| {
				preventDelete?: false | undefined;
				onDelete: (id: string) => void;
				deleting: boolean;
		  }
	);

export const FileUploadItem = ({
	id,
	name,
	extension,
	types,
	typeLabelMap,
	preventDelete,
	onDelete,
	onDownload,
	deleting,
}: FileUploadItemProps) => (
	<div className="relative min-h-[50px] break-words flex space-x-[25px] p-[15px] bg-white border border-gray-200 rounded-[5px] shadow-sm text-[15px] text-gray-900">
		<CustomFileIcon extension={extension} />
		<div className="flex-1 flex flex-col space-y-[2px]">
			<span>
				{name}.{extension}
			</span>
			<span className="text-[13px] text-gray-500">
				{types.map((type) => typeLabelMap[type]).join(", ")}
			</span>
		</div>
		<div className="w-fit min-w-fit h-fit flex items-center space-x-[12px]">
			{!preventDelete && (
				<ConfirmationModalClickWrapper
					message={
						<div className="w-[375px] text-center break-words">
							Are you sure you want to delete
							<br />
							{name}.{extension}?
						</div>
					}
					className="items-center"
					confirmButtonText="Delete"
					confirmationButtonClassName="border-[1px] border-red-500 bg-white text-red-500 hover:bg-red-500 hover:bg-opacity-10"
					onConfirm={() => onDelete(id)}
				>
					<HiOutlineTrash className="text-[19px] stroke-[2px] text-red-400 hover:text-red-600 cursor-pointer" />
				</ConfirmationModalClickWrapper>
			)}
			<Button color="none" onClick={() => !!onDownload && onDownload(id)}>
				<HiOutlineArrowDownTray className="text-[19px] stroke-[2px] text-gray-600 hover:text-gray-800 cursor-pointer" />
			</Button>
		</div>
		{deleting && (
			<div className="absolute top-0 right-0 bottom-0 left-0 rounded-[5px] bg-striped bg-white bg-opacity-80 !m-0"></div>
		)}
	</div>
);

export const PendingFileUploadItem = ({
	name,
	extension,
	types,
	typeLabelMap,
}: Omit<FileUploadItemProps, "fileId" | "onDelete" | "deleting" | "onDownload">) => (
	<div className="opacity-60 min-h-[50px] break-words flex items-center space-x-[25px] p-[15px] bg-white border border-gray-200 rounded-[5px] shadow-sm text-[15px] text-gray-900">
		<CustomFileIcon extension={extension} />
		<div className="flex-1 flex flex-col space-y-[2px]">
			<span>
				{name}.{extension}
			</span>
			<span className="text-[13px] text-gray-500">
				{types.map((type) => typeLabelMap[type]).join(", ")}
			</span>
		</div>
		<HiXMark className="text-[19px] opacity-0" />
	</div>
);
