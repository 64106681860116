import { Dtos } from "@inrev/common";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { DateTime } from "luxon";
import { memo, useEffect, useRef } from "react";
import { BiBold, BiItalic, BiPaperPlane } from "react-icons/bi";
import { FaUnderline } from "react-icons/fa";
import { filterXSS } from "xss";
import { cn } from "../lib/utils";
import { Button } from "./Button";
import { StackedCard } from "./StackedCard";

export type CommentsProps = {
	comments: Dtos.Comment.Get.Response[] | undefined;
	commentsLoading: boolean;
	createComment: (message: string) => void;
	createCommentLoading: boolean;
};

const parseCommentMessage = (message: string) => {
	if (message.slice(0, 6) === "giphy:") {
		return `<img src="https://i.giphy.com/${filterXSS(message.split(":")[1], { whiteList: {}, stripIgnoreTag: true })}.webp" class="max-h-[200px]" />`;
	}
	return filterXSS(message);
};

export const Comment = memo(({ comment }: { comment: Dtos.Comment.Get.Response }) => {
	return (
		<div className="flex flex-col shrink-0 px-[12px] space-y-[12px]">
			<div className="flex items-center space-x-[4px]">
				<span className="text-[13px] font-[550] text-gray-700">
					{comment.fromUserFirstName} {comment.fromUserLastName}
					<span className="text-[12px] ml-[6px]">
						{comment.fromUserType === "admin" ? "(inRev)" : ""}
					</span>
				</span>
				<span className="text-gray-400 text-[11px] font-medium">
					{DateTime.fromISO(comment.createdAt).toRelative()}
				</span>
			</div>
			<div
				className="text-[14px] px-[20px] py-[18px] bg-white shadow-sm rounded-lg"
				dangerouslySetInnerHTML={{ __html: parseCommentMessage(comment.message) }}
			></div>
		</div>
	);
});

export const Comments = ({
	comments,
	commentsLoading,
	createComment,
	createCommentLoading,
}: CommentsProps) => {
	const messageContainerRef = useRef<HTMLDivElement>(null);

	const editor = useEditor({
		editable: true,
		extensions: [
			StarterKit.configure({
				bold: {
					HTMLAttributes: {
						class: "font-[550]",
					},
				},
			}),
			Underline,
			Placeholder.configure({
				placeholder: "Type here to add a comment...",
				emptyEditorClass:
					"before:h-0 before:float-left before:content-[attr(data-placeholder)] before:text-gray-400 before:italic cursor-text",
			}),
		],
		editorProps: {
			attributes: {
				class:
					"outline-none focus:outline-none px-[4px] pb-[34px] pt-[18px] min-h-[100px] text-[14.5px]",
			},
		},
	});

	useEffect(() => {
		if (!!comments && comments.length && messageContainerRef.current) {
			messageContainerRef.current.scroll({
				top: messageContainerRef.current.scrollHeight,
				behavior: "auto",
			});
		}
	}, [comments, messageContainerRef.current]);

	useEffect(() => {
		if (editor && !createCommentLoading) {
			editor.commands.clearContent();
		}
	}, [createCommentLoading]);

	const handleCreateComment = (message: string) => {
		const giphyUrlIndex = message.indexOf("https://media.giphy.com/media/");
		if (giphyUrlIndex >= 0) {
			let giphyId = message.slice(giphyUrlIndex + 30).split("/")[0];
			createComment(`giphy:${giphyId}`);
		} else {
			createComment(message);
		}
	};

	if (!editor) return null;
	return (
		<StackedCard className="flex flex-col w-full">
			{!!comments && comments.length > 0 && (
				<div
					ref={messageContainerRef}
					className="w-full h-fit border-b pt-[28px] pb-[24px] border-b-gray-200 bg-gray-50 rounded-t-md max-h-[450px] overflow-y-auto overscroll-none"
				>
					{commentsLoading && (
						<span className="w-full text-center text-[14px] italic">Loading comments...</span>
					)}
					{!!comments && comments.length > 0 && (
						<div className="flex flex-col w-full shrink-0 h-fit px-[24px] last:mb-[20px] space-y-[28px]">
							{!!comments &&
								comments.map((comment, index) => <Comment key={index} comment={comment} />)}
						</div>
					)}
				</div>
			)}
			<div
				tabIndex={1}
				className={cn(
					"w-full bg-white rounded-md px-[16px] focus:outline-gray-600 focus-within:outline focus-within:outline-1",
					comments?.length && "rounded-t-none",
					createCommentLoading ? "pointer-events-none opacity-60" : "",
				)}
			>
				<div className="flex items-center h-[40px] space-x-[3px] border-b border-b-gray-100 justify-between">
					<div className="flex items-center space-x-[3px] min-w-fit">
						<div
							onMouseDown={(e) => {
								e.preventDefault();
								e.stopPropagation();
								editor.chain().focus().toggleBold().run();
							}}
							className={cn(
								"flex items-center justify-center w-[26px] h-[26px] rounded text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer",
								editor.isActive("bold")
									? "bg-gray-200/80 hover:bg-gray-200/80 text-gray-900"
									: "hover:bg-gray-100 hover:text-gray-900",
							)}
						>
							<BiBold className="text-[15px]" />
						</div>
						<div
							onMouseDown={(e) => {
								e.preventDefault();
								e.stopPropagation();
								editor.chain().focus().toggleItalic().run();
							}}
							className={cn(
								"flex items-center justify-center w-[26px] h-[26px] rounded text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer",
								editor.isActive("italic")
									? "bg-gray-200/80 hover:bg-gray-200/80 text-gray-900"
									: "hover:bg-gray-100 hover:text-gray-900",
							)}
						>
							<BiItalic className="text-[14px] mt-[-.75px]" />
						</div>
						<div
							onMouseDown={(e) => {
								e.preventDefault();
								e.stopPropagation();
								editor.chain().focus().toggleUnderline().run();
							}}
							className={cn(
								"flex items-center justify-center w-[26px] h-[26px] rounded text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer",
								editor.isActive("underline")
									? "bg-gray-200/80 hover:bg-gray-200/80 text-gray-900"
									: "hover:bg-gray-100 hover:text-gray-800",
							)}
						>
							<FaUnderline className="text-[11.5px] mt-[1px]" />
						</div>
					</div>
					<Button
						color="light-blue"
						filled
						className="h-[28px] text-[13.5px] px-[12px] disabled:cursor-default disabled:bg-gray-300/70 disabled:text-gray-700"
						thinFont
						disabled={editor.isEmpty}
						onClick={() => !createCommentLoading && handleCreateComment(editor.getHTML())}
					>
						<BiPaperPlane className="text-[15px]" />
					</Button>
				</div>
				<EditorContent editor={editor} />
			</div>
		</StackedCard>
	);
};
