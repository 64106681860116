import { zodResolver } from "@hookform/resolvers/zod";
import { IndemnityAgreementSignatureMethod, SuretyAccountId, SuretyType } from "@inrev/common";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { HiOutlineArrowDownTray, HiOutlineEnvelope } from "react-icons/hi2";
import { ConfirmationModalClickWrapper } from "../../../../../components/layout/ConfirmationModalClickWrapper";
import { LoadingModal } from "../../../../../components/layout/LoadingModal";
import { FormItem } from "../../../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../../../components/layout/form/FormItemGroup";
import { Button } from "../../../../../components/ui/Button";
import { Icon } from "../../../../../components/ui/Icon";
import { InfoCard } from "../../../../../components/ui/InfoCard";
import { StackedCardGrayBody } from "../../../../../components/ui/StackedCardGrayBody";
import { FormDropdown } from "../../../../../components/ui/form/FormDropdown";
import { FormInput } from "../../../../../components/ui/form/FormInput";
import { FormItemLabel } from "../../../../../components/ui/form/FormItemLabel";
import { FormQuestionLabel } from "../../../../../components/ui/form/FormQuestionLabel";
import {
	indemnityAgreementSignatureMethodTypeDisplayMap,
	indemnityAgreementSignatureMethodTypeLabelMap,
} from "../../../../../constants";
import {
	useCreateAccountIndemnityAgreement,
	useGetAccountIndemnityAgreementFileUrl,
} from "../../../../../domain/agent/account/api";
import {
	CreateAccountIndemnityAgreementDto,
	SubmittedSuretyAccount,
} from "../../../../../domain/agent/account/types";
import { createAccountIndemnityAgreementDtoSchema } from "../../../../../domain/agent/account/validation";
import { useScrollToId } from "../../../../../utils";
import { AccountIndemnitorList } from "./AccountIndemnitorList";

export type AccountIndemnityAgreementSectionProps = {
	account: SubmittedSuretyAccount;
};

type AccountIndemnityFormData = {
	suretyType: SuretyType;
	signatureMethod: "electronic" | "physical" | "";
	companySigners: { contactId: string; signerEmail: string }[];
	individualSigners: { contactId: string; signerEmail: string }[];
};

const getDefaultFormData = (
	suretyType: SuretyType,
	requiredIndemnitors: NonNullable<
		SubmittedSuretyAccount["contract"]
	>["indemnityAgreement"]["indemnitors"],
): AccountIndemnityFormData => {
	return {
		suretyType,
		signatureMethod: "",
		companySigners:
			requiredIndemnitors.companies.map((company) => ({
				contactId: company.id,
				signerEmail: "",
			})) ?? [],
		individualSigners:
			requiredIndemnitors.individuals.map((individual) => ({
				contactId: individual.id,
				signerEmail: individual.signerEmail ?? "",
			})) ?? [],
	};
};

export const AccountIndemnityAgreementSection = ({
	account,
}: AccountIndemnityAgreementSectionProps) => {
	const indemnityAgreement = account.contract.indemnityAgreement;
	const domReadyRef = useScrollToId("start");

	const formMethods = useForm<AccountIndemnityFormData, any, CreateAccountIndemnityAgreementDto>({
		defaultValues: getDefaultFormData(
			SuretyType.contract,
			account.contract.indemnityAgreement.indemnitors,
		),
		reValidateMode: "onBlur",
		resolver: zodResolver(createAccountIndemnityAgreementDtoSchema),
	});
	const signatureMethod = formMethods.watch("signatureMethod");
	const signatureMethodOptions = useMemo(() => {
		return (
			Object.keys(
				indemnityAgreementSignatureMethodTypeLabelMap,
			) as IndemnityAgreementSignatureMethod[]
		)
			.filter((key: IndemnityAgreementSignatureMethod) =>
				indemnityAgreement.allowedSignatureMethods.includes(key),
			)
			.map((key: IndemnityAgreementSignatureMethod) => ({
				value: key,
				label: indemnityAgreementSignatureMethodTypeLabelMap[key],
			}));
	}, [indemnityAgreement.allowedSignatureMethods]);
	const [hideBody, setHideBody] = useState<boolean>(signatureMethod === "");

	const { createAccountIndemnityAgreement, createAccountIndemnityAgreementIsLoading } =
		useCreateAccountIndemnityAgreement();
	const { getIndemnityAgreementFileUrl, indemnityAgreementFileUrlLoading } =
		useGetAccountIndemnityAgreementFileUrl(
			account.id as SuretyAccountId,
			SuretyType.contract,
			true,
		);
	const indemnitors:
		| {
				id: string;
				name: string;
				type: "company" | "individual";
				completed?: boolean | undefined;
		  }[]
		| undefined = useMemo(() => {
		return [
			...(indemnityAgreement.indemnitors.companies
				.sort((company) => {
					if (company.id === account.primaryCompanyId) return -1;
					else return 1;
				})
				.map((indemnitor) => ({ ...indemnitor, type: "company" as const })) ?? []),
			...(indemnityAgreement.indemnitors.individuals?.map((indemnitor) => ({
				...indemnitor,
				type: "individual" as const,
			})) ?? []),
		];
	}, [indemnityAgreement.indemnitors.companies, indemnityAgreement.indemnitors.individuals]);

	const handleSubmit = (data: CreateAccountIndemnityAgreementDto) => {
		createAccountIndemnityAgreement({ accountId: account.id, data }).then(
			async () => await getIndemnityAgreementFileUrl(),
		);
	};

	useEffect(() => {
		setHideBody(signatureMethod !== "electronic");
	}, [signatureMethod]);

	if (
		(account.status === "approved" || account.status === "active") &&
		!!indemnityAgreement &&
		indemnityAgreement.status !== "complete" &&
		!!indemnitors?.length
	) {
		return (
			<div ref={domReadyRef} id="indemnity" className="flex flex-col w-full space-y-[20px]">
				<div className="flex items-center justify-between space-x-[20px]">
					<div className="text-[20px] text-gray-900 font-medium">Indemnity Agreement</div>
					<div className="flex items-center gap-[10px] text-[14px] text-gray-600 italic">
						<div className="flex items-center space-x-[7px] min-w-fit">
							<Icon type="action-required" className="h-[17px] mt-[2px]" />
							<span>Incomplete</span>
						</div>
						{indemnityAgreement.status === "pending" &&
							indemnityAgreement.signatureMethod === "electronic" && (
								<>
									<div className="w-[1px] h-[18px] bg-gray-200"></div>
									<span className="text-[13px] text-gray-500 font-medium">
										{indemnitors.filter((indemnitor) => indemnitor.completed).length} /{" "}
										{indemnitors.length}
									</span>
								</>
							)}
					</div>
				</div>
				<form>
					<StackedCardGrayBody
						contentClassName="flex flex-col space-y-[45px] p-[45px] pt-[35px]"
						hideBody={
							(signatureMethod === "" && indemnityAgreement.status === "incomplete") ||
							(indemnityAgreement.status === "pending" &&
								indemnityAgreement.signatureMethod !== "physical")
						}
						header={
							<div className="flex flex-col space-y-[28px] p-[5px] pb-[10px]">
								<FormItem condensed>
									<FormQuestionLabel className="text-[15px] text-gray-800 font-semibold" condensed>
										Signature Method
									</FormQuestionLabel>
									{indemnityAgreement.status === "incomplete" && (
										<FormDropdown
											condensed
											control={formMethods.control}
											name="signatureMethod"
											options={signatureMethodOptions}
											placeholder="Select one"
										/>
									)}
									{indemnityAgreement.status === "pending" && (
										<span className="text-[14px] pl-[4px] !mt-[10px]">
											{
												indemnityAgreementSignatureMethodTypeDisplayMap[
													indemnityAgreement.signatureMethod
												]
											}
										</span>
									)}
								</FormItem>
								<div className="flex flex-col space-y-[12px]">
									<div className="text-[15px] text-gray-800 font-semibold">
										Required Indemnitors
									</div>
									<AccountIndemnitorList indemnityAgreement={indemnityAgreement} />
								</div>
							</div>
						}
					>
						<div className="flex flex-col h-fit min-h-fit space-y-[30px]">
							<FormItemGroup>
								{(signatureMethod === "physical" ||
									(indemnityAgreement.status === "pending" &&
										indemnityAgreement.signatureMethod === "physical")) && (
									<div className="flex flex-col space-y-[30px]">
										<InfoCard>
											By selecting "Wet signatures", you are responsible for collecting signatures
											from indemnitors, creating a scanned copy of the signed agreement, and sending
											the scanned copy to your inRev account representative
										</InfoCard>
										{indemnityAgreement.status === "incomplete" && (
											<ConfirmationModalClickWrapper
												message="Are you sure you want to generate this indemnity agreement for wet signatures?"
												onConfirm={() => formMethods.handleSubmit(handleSubmit)()}
												className="max-w-[500px] self-center"
											>
												<Button
													color="light-blue"
													filled
													thinFont
													className="flex items-center space-x-[10px] px-[50px] self-center h-[36px]"
												>
													<HiOutlineArrowDownTray className="text-white text-[18px] stroke-[2]" />
													<span>Generate & Download Indemnity Agreement</span>
												</Button>
											</ConfirmationModalClickWrapper>
										)}
										{indemnityAgreement.status === "pending" && (
											<Button
												color="light-blue"
												filled
												thinFont
												className="flex items-center space-x-[10px] px-[50px] self-center h-[36px]"
												onClick={getIndemnityAgreementFileUrl}
												loading={indemnityAgreementFileUrlLoading}
											>
												<HiOutlineArrowDownTray className="text-white text-[18px] stroke-[2]" />
												<span>Download Indemnity Agreement</span>
											</Button>
										)}
									</div>
								)}
								{!hideBody && (
									<div className="flex flex-col space-y-[45px]">
										<div className="flex flex-col space-y-[15px] w-full h-fit min-h-fit">
											{account.contract.indemnityAgreement.indemnitors.companies.map(
												(company, index) => (
													<FormItemGroup key={index} className="py-[8px] rounded-sm space-y-[10px]">
														<div className="flex items-center space-x-[10px] font-semibold">
															<span className="text-gray-400 text-[13px]">{index + 1}.</span>
															<span className="text-gray-900 text-[14px]">{company.name}</span>
														</div>
														<FormItem condensed className="px-[18px]">
															<FormItemLabel condensed>Signer Email</FormItemLabel>
															<FormInput
																control={formMethods.control}
																name={`companySigners.${index}.signerEmail`}
																className="bg-white"
																condensed
															/>
														</FormItem>
													</FormItemGroup>
												),
											)}
											{account.contract.indemnityAgreement.indemnitors.individuals.map(
												(individual, index) => (
													<FormItemGroup
														key={
															account.contract.indemnityAgreement.indemnitors.companies.length +
															index
														}
														className="py-[8px] rounded-sm space-y-[10px]"
													>
														<div className="flex items-center space-x-[10px] font-semibold">
															<span className="text-gray-400 text-[13px]">
																{account.contract.indemnityAgreement.indemnitors.companies.length +
																	index +
																	1}
																.
															</span>
															<span className="text-gray-900 text-[14px]">{individual.name}</span>
														</div>
														<FormItem condensed className="px-[18px]">
															<FormItemLabel condensed>Email</FormItemLabel>
															<FormInput
																control={formMethods.control}
																name={`individualSigners.${index}.signerEmail`}
																className="bg-white"
																condensed
															/>
														</FormItem>
													</FormItemGroup>
												),
											)}
										</div>
										<ConfirmationModalClickWrapper
											message="Are you sure you want to send this indemnity agreement for e-signatures?"
											onConfirm={() => formMethods.handleSubmit(handleSubmit)()}
											className="max-w-[500px] self-center"
										>
											<Button
												color="light-blue"
												filled
												thinFont
												className="flex items-center space-x-[10px] px-[50px] self-center h-[36px]"
											>
												<HiOutlineEnvelope className="text-white text-[18px] stroke-[1.75] fill-white/20" />
												<span>Send Indemnity Agreement For E-Signature</span>
											</Button>
										</ConfirmationModalClickWrapper>
									</div>
								)}
							</FormItemGroup>
						</div>
					</StackedCardGrayBody>
				</form>
				{createAccountIndemnityAgreementIsLoading && <LoadingModal />}
			</div>
		);
	}

	return <></>;
};
