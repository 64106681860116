import { RadioGroup } from "@headlessui/react";
import { FileExtension, FileId, FileType } from "@inrev/common";
import { lookup } from "mrmime";
import { useEffect, useMemo, useState } from "react";
import { HiCheck } from "react-icons/hi2";
import { v4 } from "uuid";
import { LocallyUploadedFile } from "../../types";
import { getNameAndExtensionFromFileName } from "../../utils";
import { Modal } from "../layout/Modal";
import { cn } from "../lib/utils";
import { Button } from "./Button";
import { CheckboxOption } from "./CheckboxOption";
import { Dropzone } from "./Dropzone";
import { FileUploadItem } from "./FileUploadItem";
import { RadioOption } from "./RadioOption";

export type LocalFileUploadProps<
	TFileType extends FileType = FileType,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
	TAllowedType extends Extract<TFileType, keyof TAllowedTypesAndLabels> = Extract<
		TFileType,
		keyof TAllowedTypesAndLabels
	>,
	TRequired extends TAllowedType[] | undefined = TAllowedType[] | undefined,
> = {
	value: LocallyUploadedFile<TFileType>[];
	onChange: (value: LocallyUploadedFile<TFileType>[]) => void;
	onBlur?: () => void;
	allowedTypesAndLabels: TAllowedTypesAndLabels;
	allowedExtensions?: FileExtension[];
	requiredTypes?: TRequired;
	typeQuestionText?: string;
	maxTypesPerFile?: number;
	subsequentFileTypeRestriction?: true;
	maxFiles?: number;
	hideChecklist?: true;
	showErrors?: boolean;
	preventDelete?: boolean;
	className?: string;
};

export const LocalFileUpload = <
	TFileType extends FileType = FileType,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
	TAllowedType extends Extract<TFileType, keyof TAllowedTypesAndLabels> = Extract<
		TFileType,
		keyof TAllowedTypesAndLabels
	>,
	TRequired extends TAllowedType[] | undefined = TAllowedType[] | undefined,
>({
	value: files,
	onChange: setFiles,
	onBlur,
	allowedTypesAndLabels,
	allowedExtensions,
	requiredTypes,
	typeQuestionText,
	maxTypesPerFile,
	subsequentFileTypeRestriction,
	maxFiles,
	showErrors,
	hideChecklist,
	preventDelete,
	className,
}: LocalFileUploadProps<TFileType, TAllowedTypesAndLabels, TAllowedType, TRequired>) => {
	const [showTypeSelection, setShowTypeSelection] = useState<boolean>(false);
	const [selectedTypes, setSelectedTypes] = useState<TFileType[]>([]);
	const [fileForTypeSelection, setFileForTypeSelection] = useState<File | undefined>();
	const allowedTypes = useMemo(() => {
		const types = Object.keys(allowedTypesAndLabels);
		if (!types.length) throw new Error();
		return types as [TFileType, ...TFileType[]];
	}, [allowedTypesAndLabels]);
	const atLeastOneSelectedType = (
		selectedTypes: TFileType[],
	): selectedTypes is [TFileType, ...TFileType[]] => {
		return selectedTypes.length > 0;
	};
	const requiredTypesFulfilled = useMemo(() => {
		if (!requiredTypes?.length) return true;
		else
			return requiredTypes.every((requiredType) =>
				files.some((file) => file.types.includes(requiredType)),
			);
	}, [requiredTypes, files]);

	const uploadFile = (rawFile: File, types: [TFileType, ...TFileType[]]) => {
		const { fileName, extension } = getNameAndExtensionFromFileName(rawFile.name);
		const reader = new FileReader();
		reader.onload = () => {
			let base64 = reader.result;
			if (base64 !== null && typeof base64 === "string") {
				base64 = base64.split(",")[1];
				setFiles([...files, { id: v4() as FileId, name: fileName, extension, types, base64 }]);
			}
		};
		reader.readAsDataURL(rawFile);
	};

	const handleUploadTypeSelection = () => {
		if (!atLeastOneSelectedType(selectedTypes)) throw new Error();
		if (!fileForTypeSelection) throw new Error();
		uploadFile(fileForTypeSelection, selectedTypes);
		setShowTypeSelection(false);
	};

	const handleUpload = (file: File) => {
		if (allowedTypes.length === 1) {
			uploadFile(file, allowedTypes);
		} else if (subsequentFileTypeRestriction && files.length) {
			uploadFile(file, files[0].types);
		} else {
			setFileForTypeSelection(file);
		}
	};

	useEffect(() => {
		onBlur && onBlur();
	}, [files]);

	const handleDelete = (id: string) => {
		setFiles(files.filter((file) => file.id !== id));
	};

	useEffect(() => {
		onBlur && onBlur();
	}, [files]);

	useEffect(() => {
		if (fileForTypeSelection !== undefined) setShowTypeSelection(true);
	}, [fileForTypeSelection]);

	useEffect(() => {
		if (!showTypeSelection) {
			if (selectedTypes !== undefined) setSelectedTypes([]);
			if (fileForTypeSelection !== undefined) setFileForTypeSelection(undefined);
		}
	}, [showTypeSelection]);

	return (
		<div className={cn("relative flex flex-col space-y-[25px] w-full", className)}>
			{requiredTypes && !!requiredTypes.length && !hideChecklist && (
				<div
					className={cn(
						"relative flex-1 flex flex-col space-y-[5px] pl-[23px] after:absolute after:w-[3px] after:rounded-full after:bg-gray-100 after:top-0 after:bottom-0 after:left-0",
						showErrors && !requiredTypesFulfilled ? "after:bg-red-100" : undefined,
					)}
				>
					{requiredTypes.map((type) => {
						const typeIncluded = files.some((file) => file.types.includes(type));
						return (
							<div
								key={type}
								className={cn(
									"flex items-center space-x-[10px] text-[15px]",
									typeIncluded ? "opacity-100" : "opacity-70",
									showErrors && !typeIncluded ? "text-red-500 opacity-100" : undefined,
								)}
							>
								<div
									className={cn(
										"relative aspect-square h-4 w-4 rounded-full border border-gray-400 bg-white focus:outline-none flex items-center justify-center",
										showErrors && !typeIncluded ? "border-red-500" : undefined,
									)}
								>
									{typeIncluded && (
										<div className="flex items-center justify-center absolute top-[-1px] left-[-1px] aspect-square h-4 w-4 rounded-full bg-inrev-light-blue ml-[-1] mt-[-1]">
											<HiCheck className="text-[10px] text-white stroke-[4px]" />
										</div>
									)}
								</div>
								<div>{allowedTypesAndLabels[type]}</div>
							</div>
						);
					})}
				</div>
			)}
			<div className="w-full flex flex-col space-y-[15px]">
				{showErrors &&
					!requiredTypesFulfilled &&
					hideChecklist &&
					requiredTypes &&
					!!requiredTypes.length && <div className="text-[13px] text-red-500">Required</div>}
				{(maxFiles === undefined || files.length < maxFiles) && (
					<Dropzone allowedExtensions={allowedExtensions} onSuccess={handleUpload} />
				)}
				{files.length > 0 && (
					<div className="w-full max-w-full flex flex-col space-y-[10px] cursor-default">
						{files.map((file, index) => (
							<FileUploadItem
								key={index}
								{...file}
								typeLabelMap={allowedTypesAndLabels}
								preventDelete={preventDelete}
								onDelete={handleDelete}
								onDownload={() => {
									const mimeType = lookup(file.extension);
									if (!!mimeType) {
										const dataURI = `data:${mimeType};base64,${file.base64}`;
										const downloadLink = document.createElement("a");
										downloadLink.download = `${file.name}.${file.extension}`;
										downloadLink.href = dataURI;
										downloadLink.style.display = "none";
										document.body.appendChild(downloadLink);
										downloadLink.click();
										document.body.removeChild(downloadLink);
									}
								}}
								deleting={false}
							/>
						))}
					</div>
				)}
			</div>
			{showTypeSelection && (
				<Modal onClickOutside={() => setShowTypeSelection(false)}>
					<div
						className={cn(
							"py-[35px] px-[35px] bg-white flex flex-col justify-center rounded-md shadow-lg space-y-[35px]",
							className,
						)}
					>
						<div className="text-[16px] text-gray-600 font-thin">
							{typeQuestionText ?? "What type of file is this?"}
						</div>
						{maxTypesPerFile === 1 && (
							<RadioGroup
								value={selectedTypes[0]}
								onChange={(value) => setSelectedTypes([value])}
								className="flex flex-col space-y-[35px]"
							>
								{allowedTypes.map((type) => (
									<RadioOption
										value={type}
										onSelect={() => setSelectedTypes([type])}
										label={allowedTypesAndLabels[type]}
										key={type}
									/>
								))}
							</RadioGroup>
						)}
						{maxTypesPerFile !== 1 &&
							allowedTypes.map((type) => {
								const checked = selectedTypes.includes(type);
								return (
									<CheckboxOption
										value={type}
										checked={checked}
										onChange={(updateChecked) =>
											setSelectedTypes(
												!updateChecked
													? selectedTypes?.filter((selectedType) => selectedType !== type)
													: [...selectedTypes, type],
											)
										}
										label={allowedTypesAndLabels[type]}
										key={type}
									/>
								);
							})}
						<Button color="light-blue" filled onClick={handleUploadTypeSelection}>
							Upload
						</Button>
					</div>
				</Modal>
			)}
		</div>
	);
};
